$widget-event-offset: $grid-gutter-width;
$widget-event-offset-lg: $grid-gutter-width;

.widget-event {
	width: 100%;
	height: auto;
	z-index: 0;
	position: relative;
	@extend .container-fluid;

	.widget-event__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.widget-event__container {
			.widget-event__container-item {
				position: relative;
				display: flex;
				align-items: center;
				z-index: 2;
				flex-wrap: wrap;
				padding-top: ($spacer * 5);
				padding-bottom: ($spacer * 5);

				.widget-event__header {
					width: 100%;
					flex-shrink: 0;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;
					margin-bottom: ($spacer * 4);

					.widget-event__title {
						line-height: 1;
						margin-bottom: 0;

						span {
							text-align: left;
							color: $primary;
							background-color: $white;
							padding: ($spacer * 0.3) ($spacer);
							box-decoration-break: clone;
							font-size: $font-post-fullscreen-xs;
							line-height: $line-height-post-fullscreen-xs;
							-webkit-box-decoration-break: clone;
						}
					}

					.widget-event__details {
						.widget-event__details-date {
							margin-top: ($spacer * 2);
						}

						.widget-event__details-location {
							margin-top: ($spacer * 0.5);
						}

						.widget-event__details-date,
						.widget-event__details-location {
							font-size: $font-size--100;
							line-height: 1;
						}

						.widget-event__details-link {
							margin-top: ($spacer * 2);

							.btn {
								&.btn--default {
									svg.icon {
										transition-property: transform;
										transition-duration: 0.8s;
										animation-name: bounce;
										animation-duration: 0.8s;
										animation-iteration-count: infinite;
										animation-timing-function: ease-in-out;
									}
									@keyframes bounce {
										0%,
										100%,
										20%,
										60% {
											-webkit-transform: translateX(0);
											transform: translateX(0);
										}

										40% {
											-webkit-transform: translateX(-5px);
											transform: translateX(-5px);
										}

										80% {
											-webkit-transform: translateX(-2px);
											transform: translateX(-2px);
										}
									}
								}
							}
						}
					}
				}

				.widget-event__body {
					width: auto;
					position: relative;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;

					.widget-event__body-link {
						position: absolute;
						background: $white;
						width: 100px;
						height: 100px;
						display: flex;
						align-items: center;
						justify-content: center;
						z-index: 3;
						margin-left: auto;
						margin-right: auto;
						box-shadow: $box-shadow--100;
						bottom: -10px;
						right: 10px;

						svg {
							width: 40px;
							height: 40px;
							fill: $primary;
							margin-left: 5px;
						}
					}

					.widget-event__body-item {
						.dialog {
							margin-bottom: ($spacer * 2);

							&:nth-child(2) {
								opacity: 1;
							}

							&:last-child {
								opacity: 1;
								margin-bottom: 0;

								.dialog__wrapper {
									.dialog__body {
										.dialog__article {
											&:after {
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.widget-event__container-back {
				background: $secondary;
				width: 100%;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.widget-event {
		transform: translateY($offset-top-sm);

		.widget-event__wrapper {
			.widget-event__container {
				.widget-event__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);

					.widget-event__header {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						margin-left: 8.33333%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
						margin-bottom: ($spacer * 4);

						.widget-event__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 1);
								font-size: $font-post-fullscreen-sm;
								line-height: $line-height-post-fullscreen-sm;
							}
						}
					}

					.widget-event__body {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						margin-left: 8.33333%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.widget-event {
		transform: translateY($offset-top-md);

		.widget-event__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.widget-event__container {
				.widget-event__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);

					.widget-event__header {
						flex: 0 0 calc(75%);
						max-width: calc(75%);
						padding: 0 ($grid-gutter-width / 2);
						margin-bottom: ($spacer * 5);
						margin-left: 0;
						margin-bottom: ($spacer * 2.5);

						.widget-event__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 2);
								font-size: $font-post-fullscreen-md;
								line-height: $line-height-post-fullscreen-md;
							}
						}
					}

					.widget-event__body {
						padding: 0 ($grid-gutter-width / 2);
						flex: 0 0 calc(83.33333%);
						max-width: calc(83.33333%);
						margin-left: 0;

						.widget-event__body-item {
							.dialog {
								margin-bottom: ($spacer * 2.5);
							}
						}

						.widget-event__body-link {
							width: 100px;
							height: 100px;
							bottom: 50px;
							left: inherit;
							right: 0;
						}
					}
				}

				.widget-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
					transform: translateX(-$container-outer-padding-md);
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.widget-event {
		transform: translateY($offset-top-lg);

		.widget-event__wrapper {
			.widget-event__container {
				flex: 0 0 100%;
				max-width: 100%;

				.widget-event__container-item {
					width: 100%;
					padding: ($spacer * 10) 0;
					display: flex;
					align-items: flex-start;

					.widget-event__header {
						margin-left: 0;
						flex: 0 0 calc(33.33333%);
						max-width: calc(33.33333%);
						margin-bottom: 0;

						.widget-event__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 2);
								font-size: 4vw;
						    line-height: calc(4vw * 1.5);
							}
						}

						.widget-event__details {
							.widget-event__details-date {
								margin-top: ($spacer * 3);
							}

							.widget-event__details-date,
							.widget-event__details-location {
								font-size: $font-size--175;
								line-height: 1.4;
							}
							.widget-event__details-link {
								margin-top: ($spacer * 3);
							}
						}
					}

					.widget-event__body {
						padding: 0 ($grid-gutter-width / 2);
						flex: 0 0 calc(50%);
						max-width: calc(50%);
						margin-left: 0;

						.widget-event__body-item {
							.dialog {
								margin-bottom: ($spacer * 2.5);
							}
						}

						.widget-event__body-link {
							width: 110px;
							height: 110px;
							bottom: -25px;
							right: 0;
							left: inherit;
						}
					}
				}

				.widget-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					max-width: calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(-$container-outer-padding-lg);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.widget-event {
		transform: translateY($offset-top-xl);

		.widget-event__wrapper {
			.widget-event__container {
				.widget-event__container-item {
					.widget-event__header {
						margin-left: 8.33333%;
						flex: 0 0 calc(33.33333%);
						max-width: calc(33.33333%);
					}

					.widget-event__body {
						flex: 0 0 calc(41.66667%);
						max-width: calc(41.66667%);

						.widget-event__body-item {
							.dialog {
								margin-bottom: ($spacer * 3);
							}
						}

						.widget-event__body-link {
							bottom: -20px;
							right: 0;
							left: inherit;
						}
					}
				}

				.widget-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
					max-width: calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.widget-event {
		transform: translateY($offset-top-xl);

		.widget-event__wrapper {
			.widget-event__container {
				.widget-event__container-item {
					.widget-event__header {
						.widget-event__title {
							span {
								font-size: $font-post-fullscreen-xxl;
								line-height: $line-height-post-fullscreen-xxl;
							}
						}
					}

					.widget-event__body {
						.widget-event__body-link {
							bottom: -20px;
							right: 0;
							left: inherit;
						}
					}
				}

				.widget-event__container-back {
					flex: 0 0 calc((91.66667% - (#{$grid-gutter-width})));
					max-width: calc((91.66667% - (#{$grid-gutter-width})));
					transform: translateX(calc((#{$grid-gutter-width} / 2)));
				}
			}
		}
	}
}

// Edge styles
@supports (-ms-ime-align: auto) {
	.widget-event {
		transform: translateY(0) !important;
		z-index: -1 !important;
	}
	@include media-breakpoint-up(sm) {
		.widget-event {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(md) {
		.widget-event {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(lg) {
		.widget-event {
			margin-top: calc(#{$offset-top-lg} * 1.6);
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(xl) {
		.widget-event {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(xxl) {
		.widget-event {
			transform: translateY(0) !important;
		}
	}
}
