@import "../../../../node_modules/bootstrap/scss/_mixins.scss"; // Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1720px
)!default; // Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
	xxl: 1720px
)!default;

$grid-columns: 12 !default;
$grid-gutter-width: 50px;

$container-outer-padding: 6.25vw;
$container-outer-padding-md: $grid-gutter-width;
$container-outer-padding-lg: $grid-gutter-width;
$container-outer-padding-xl: $grid-gutter-width;
$container-outer-gutter: calc((100vw - 100%) / 2);
$container-max-widht: calc(1280px + (#{$container-outer-padding-xl} * 2));

.katana {
	.row-eq-height {
		display: flex;

		.col-eq-height {
			height: 100%;
		}
	}

	.container-fluid {
		padding-right: 0;
		padding-left: 0;
	}

	@include media-breakpoint-up(md) {
		.container-fluid {
			padding-right: $container-outer-padding-md;
			padding-left:  $container-outer-padding-md;
		}
	}

	@include media-breakpoint-up(lg) {
		.container-fluid {
			padding-right: $container-outer-padding-lg;
			padding-left:  $container-outer-padding-lg;
		}
	}

	@include media-breakpoint-up(xl) {
		.container-fluid {
			padding-right: $container-outer-padding-xl;
			padding-left:  $container-outer-padding-xl;
		}
	}

	@include media-breakpoint-up(xxl) {
		.container-fluid {
			max-width: 80%;
		}
	}
}
