.section-text {
	position: relative;
	width: 100%;
	height: auto;
	transform: translateY($offset-bottom);
	margin-bottom: $article-bottom-margin;
	z-index: 2;
	@extend .container-fluid;

	.section-text__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-text__body {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex: 0 0 100%;
			max-width: 100%;
			padding-left: $container-outer-padding;
			padding-right: $container-outer-padding;

			.section-text__body-item {
				flex: 0 0 100%;
				max-width: 100%;
				background-color: $white;
				box-shadow: $box-shadow--100;
				padding-top: ($spacer * 3);
				padding-bottom: ($spacer * 3);

				.section-text__body-content {
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: $post-inner-padding;
					padding-right: $post-inner-padding;
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-text {
		transform: translateY($offset-bottom-sm);
		margin-bottom: $article-bottom-margin-sm;

		.section-text__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-text__body {
				padding: 0;
				flex: 0 0 83.33333%;
				max-width: 83.33333%;
				margin-left: 8.33333%;
				padding-left: ($grid-gutter-width / 2);
				padding-right: ($grid-gutter-width / 2);

				.section-text__body-item {
					padding: ($spacer * 6) 0;

					.section-text__body-content {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						margin-left: 8.33333%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-text {
		transform: translateY($offset-bottom-md);
		margin-bottom: $article-bottom-margin-md;

		.section-text__wrapper {
			.section-text__body {
				.section-text__body-item {
					padding: ($spacer * 8) 0;

					.section-text__body-content {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						padding: 0 ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-text {
		transform: translateY($offset-bottom-lg);
		margin-bottom: $article-bottom-margin-lg;

		.section-text__wrapper {
			.section-text__body {
				.section-text__body-item {
					padding: ($spacer * 8) 0;

					.section-text__body-content {}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-text {
		transform: translateY($offset-bottom-xl);

		.section-text__wrapper {
			.section-text__body {
				.section-text__body-item {
					padding: ($spacer * 10) 0;
				}
			}
		}
	}
}
