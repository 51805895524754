$feauted-image-offset: $grid-gutter-width;
$feauted-image-offset-sm: $grid-gutter-width;
$feauted-image-offset-md: $grid-gutter-width;
$feauted-image-offset-lg: $grid-gutter-width;
$feauted-image-offset-xl: $grid-gutter-width;
$feauted-image-offset-xxl: $grid-gutter-width;

.section-featured {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-featured__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-featured__container {
			width: 100%;

			.section-featured__container-item {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				z-index: 2;
				padding: ($spacer * 5) $container-outer-padding;

				.section-featured__image {
					height: auto;
					max-width: 80%;
					align-self: flex-start;
					z-index: 1;

					img {
						width: 100%;
						object-fit: cover;
						box-shadow: $box-shadow--100;
					}
				}
				.section-featured__content {
					display: inline;

					p {
						background-color: $white;
						padding: ($spacer * 0.7) ($spacer);
						box-decoration-break: clone;
						-webkit-box-decoration-break: clone;
						box-shadow: $box-shadow--100;
						display: inline;
						margin-bottom: 0;
						font-size: 18px;
						line-height: 2;
						margin-top: -100px;
					}

					blockquote {
						margin-top: -100px;
						margin-right: 20px;
						max-width: 80%;
						align-self: flex-end;
						z-index: 2;
						margin-bottom: 0;
						padding: 0;
						border: none;
						line-height: 1;
						font-family: $font-family-base;

						p {
							background-color: $white;
							padding: ($spacer * 0.7) ($spacer);
							box-decoration-break: clone;
							-webkit-box-decoration-break: clone;
							box-shadow: $box-shadow--100;
							display: inline;
							font-size: 18px;
							line-height: 2;
							margin-top: 0;
						}

						&:before {
							content: url("/wp-content/themes/companions/assets/images/ic_quote.svg");
							position: absolute;
							top: -45px;
							left: -10px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: $blockquote-size;
							height: $blockquote-size;
							background: $white;
							box-shadow: $box-shadow--100;
							z-index: -1;
						}
					}
				}

				&.section-featured--no-image {
					.section-featured__image {
						display: none;
					}
					.section-featured__content {
						blockquote{
						margin-top: ($spacer * 3);
						}
					}
				}
			}

			.section-featured__container-back {
				width: 100%;
				background: $secondary;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-featured {
		margin-bottom: $article-bottom-margin-sm;

		.section-featured__wrapper {
			.section-featured__container {
				.section-featured__container-item {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding: ($spacer * 6) ($grid-gutter-width / 2);

					.section-featured__image {
						max-height: none;
						max-width: 70%;

						img {
							max-height: none;
						}
					}

					.section-featured__content {
						p {
							padding: ($spacer * 0.9) ($spacer);
							line-height: 2;
							font-size: 22px;
						}

						blockquote {
							max-width: 70%;

							p {
								padding: ($spacer * 0.9) ($spacer);
								line-height: 2.25;
								line-height: 2;
								font-size: 22px;
							}

							&:before {
								width: $blockquote-size-lg;
								height: $blockquote-size-lg;
								top: -55px;
								left: -15px;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-featured {
		margin-bottom: $article-bottom-margin-md;

		.section-featured__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-featured__container {
				.section-featured__container-item {
					flex: 0 0 100%;
					max-width: 100%;
					padding: ($spacer * 6) 0;
					margin: 0;
					flex-direction: row;
					align-items: center;

					.section-featured__image {
						flex: 0 0 calc(41.66667% + #{$feauted-image-offset-md});
						max-width: calc(41.66667% + #{$feauted-image-offset-md});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 8.33333%;
						transform: translateX(-$feauted-image-offset-md);
						max-height: 720px;
						align-self: center;

						img {
							max-height: 720px;
						}
					}

					.section-featured__content {
						align-self: center;
						flex: 0 0 41.66667%;
						max-width: 41.66667%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-md);

						p{
							margin-top: 0;
						}

						blockquote {
							max-width: none;
							margin: 20px 0 0;

							&:before {
								width: $blockquote-size-lg;
								height: $blockquote-size-lg;
								top: -50px;
								left: -15px;
								padding-top: $spacer * 0.5;
							}
						}
					}
					&.section-featured--no-image {
						.section-featured__content {
							flex: 0 0 calc(83.33333% + #{$feauted-image-offset-md});
							max-width: calc(83.33333% + #{$feauted-image-offset-md});
							margin-left: 8.33333%;
							padding: 0 ($grid-gutter-width / 2);
							transform: translateX(-$feauted-image-offset-md);
						}
					}
				}

				.section-featured__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-featured {
		margin-bottom: $article-bottom-margin-lg;

		.section-featured__wrapper {
			.section-featured__container {
				padding: 0;
				flex: 0 0 100%;
				max-width: 100%;

				.section-featured__container-item {
					flex: 0 0 100%;
					max-width: 100%;
					padding: ($spacer * 10) 0;

					.section-featured__image {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-lg});
						max-width: calc(33.33333% + #{$feauted-image-offset-lg});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
						transform: translateX(-$feauted-image-offset-lg);
					}

					.section-featured__content {
						flex: 0 0 41.66667%;
						max-width: 41.66667%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-lg);

						p{
							font-size: 26px;
							line-height: 1.85;
						}

						blockquote {
							p {
								font-size: 26px;
								line-height: 1.85;
							}
						}
					}

					&.section-featured--no-image {
						.section-featured__content {
							flex: 0 0 calc(66.66667% + #{$feauted-image-offset-lg});
							max-width: calc(66.66667% + #{$feauted-image-offset-lg});
							margin-left: 16.66667%;
							padding: 0 ($grid-gutter-width / 2);
							transform: translateX(-$feauted-image-offset-md);
						}
					}
				}

				.section-featured__container-back {
					flex: 0 0 calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					max-width: calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					margin-left: calc((16.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-featured {
		.section-featured__wrapper {
			.section-featured__container {
				.section-featured__container-item {
					.section-featured__image {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-xl});
						max-width: calc(33.33333% + #{$feauted-image-offset-xl});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
						transform: translateX(-$feauted-image-offset-xl);
					}

					.section-featured__content {
						flex: 0 0 41.66667%;
						max-width: 41.66667%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-xl);


						p{
							font-size: 28px;
							line-height: 2;
							padding: ($spacer * 1.1) ($spacer);
						}

						blockquote {
							p {
								font-size: 28px;
								line-height: 2;
								padding: ($spacer * 1.1) ($spacer);
							}
						}
					}

					&.section-featured--no-image {
						.section-featured__content {
							flex: 0 0 calc(50% + #{$feauted-image-offset-xl});
							max-width: calc(50% + #{$feauted-image-offset-xl});
							margin-left: 16.66667%;
							padding: 0 ($grid-gutter-width / 2);
							transform: translateX(-$feauted-image-offset-md);
						}
					}
				}

				.section-featured__container-back {
					flex: 0 0 calc((83.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					max-width: calc((83.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					margin-left: calc((16.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-featured {
		.section-featured__wrapper {
			.section-featured__container {
				.section-featured__container-item {
					.section-featured__image {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-xl});
						max-width: calc(33.33333% + #{$feauted-image-offset-xl});
					}

					.section-featured__content {
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
					}

					&.section-featured--no-image {
						.section-featured__content {
							flex: 0 0 calc(50% + #{$feauted-image-offset-xl});
							max-width: calc(50% + #{$feauted-image-offset-xl});
							margin-left: 16.66667%;
							padding: 0 ($grid-gutter-width / 2);
							transform: translateX(-$feauted-image-offset-md);
						}
					}
				}

				.section-featured__container-back {
					flex: 0 0 calc((83.33333% - (#{$grid-gutter-width})));
					max-width: calc((83.33333% - (#{$grid-gutter-width})));
					margin-left: calc((16.66667% + (#{$grid-gutter-width} / 2)));
				}
			}
		}
	}
}
