$post-inner-padding: 7.3vw;
$post-inner-padding-sm: 40px;
$post-inner-padding-md: ($spacer * 4);
$post-inner-padding-lg: ($spacer * 4.5);
$post-inner-padding-xl: 5.5vw;

$offset-factor-md: 1;
$offset-factor-lg: 1.33333333333333;
$offset-factor-xl: 2;

$offset-factor-ms: 0.05;

.post {
	will-change: transform;
	z-index: 1;
	position: relative;
	background-color: $white;
	width: 100%;
	max-width: 100%;
	padding: $post-inner-padding;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 1;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: $container-outer-padding;
	margin-right: $container-outer-padding;
	transition: transform 0.1s;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000;
	overflow: hidden;

	.post__background-image {
		position: absolute;
		width: auto;
		height: auto;
		min-width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		object-fit: cover;
	}

	.post__thumbnail {
		width: 100%;
		height: auto;
		margin-bottom: 3vw;

		img {
			width: 100%;
			object-fit: contain;
			height: 95px;
			flex: 0 0 auto;
		}
	}

	.post__wrapper {
		width: 100%;
		height: auto;

		.post__author,
		.post__excerpt,
		.post__title {
			color: $primary;
			width: 100%;
			display: block;

			& a {
				color: $primary;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}
			}
		}

		.post__title {
			margin-bottom: $spacer;
			line-height: 1;

			a {
				span {
					line-height: calc(#{$font-post-fullscreen-xs} * 1.2);
					font-size: $font-post-fullscreen-xs;
				}
			}
		}

		.post__excerpt {
			line-height: 1.475em;
			margin-bottom: ($spacer * 0.5);
			font-size: $font-size--100;
		}

		.post__author {
			font-family: $font-family-monospace;
			font-size: $font-size--75;
			letter-spacing: 0.25px;
			text-decoration: none;
			display: inline-flex;
			width: auto;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	&.has-content-image {
		.post__wrapper {
			align-items: center;
			display: flex;
			flex-direction: column;

			.post__author,
			.post__excerpt {
				margin-left: auto;
				margin-right: auto;
			}

			.post__author,
			.post__excerpt,
			.post__title {
				text-align: center;
			}

			.post__title {
				text-align: center;

				a {
					span {
						line-height: $line-height-post-base-xs;
						font-size: $font-post-base-xs;
					}
				}
			}
		}
	}

	&.is-fullscreen {
		margin: 0;
		min-height: 62.5vw;
		height: 62.5vw;

		&.is-left-aligned {
			transform: translateY(0);

			.post__background-image {
				right: -20px;
				left: inherit;
			}
		}

		&.is-right-aligned {
			transform: translateY(0);

			.post__background-image {
				right: -20px;
				left: inherit;
			}
		}

		.post__wrapper {
			.post__title {
				text-align: left;

				a {
					span {
						text-align: left;
						color: $primary;
						background-color: $white;
						padding: ($spacer * 0.3) ($spacer);
						font-size: $font-post-fullscreen-xs;
						line-height: $line-height-post-fullscreen-xs;
						box-decoration-break: clone;
						-webkit-box-decoration-break: clone;
					}

					&:hover {
						color: $primary!important;
					}
				}
			}

			.post__author {
				text-align: left;
			}

			.post__excerpt {
				display: none !important;
			}
		}
	}

	&.has-offset-top {
		transform: translateY(calc(#{$offset-top})) translateZ(0);
	}

	&.has-offset-bottom {
		margin-top: ($spacer * 2);
		transform: translateY(calc(#{$offset-bottom})) translateZ(0);
	}

	&.has-offset-base {
		transform: translateY($offset-top) translateZ(0);
	}
}
@include media-breakpoint-up(sm) {
	.post {
		padding: $post-inner-padding-sm;
		margin: 0;
		flex: 0 0 calc(50% - (#{$grid-gutter-width} / 4));
		max-width: 50%;

		.post__thumbnail {
			margin-bottom: 1.25vw;

			img {
				height: 18vw;
				max-height: 254px;
			}
		}

		.post__wrapper {
			.post__title {
				margin-bottom: $spacer * 1;

				a {
					span {
						font-size: $font-post-fullscreen-sm;
						line-height: calc(#{$font-post-fullscreen-sm} * 1.15);
					}
				}
			}

			.post__excerpt {
				font-size: $font-size--100;
				margin-bottom: $spacer * 1;
			}
		}

		&.has-content-image {
			.post__wrapper {
				.post__author,
				.post__excerpt {
					margin-left: auto;
					margin-right: auto;
				}

				.post__author,
				.post__excerpt,
				.post__title {
					text-align: center;
				}

				.post__title {
					text-align: center;
					margin-bottom: ($spacer * 1);

					a {
						span {
							font-size: $font-post-base-sm;
							line-height: $line-height-post-base-sm;
						}
					}
				}

				.post__excerpt {
					margin-bottom: $spacer * 0.5;
				}
			}
		}

		&.is-fullscreen {
			min-height: 300px;
			height: 300px;

			.post__wrapper {
				max-width: 70%;

				.post__title {
					margin-bottom: ($spacer * 1);

					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 1);
							font-size: $font-post-fullscreen-sm;
							line-height: $line-height-post-fullscreen-sm;
						}
					}
				}
			}

			&.is-left-aligned {
				flex: 0 0 100%;
				max-width: 100%;
				transform: translateY($offset-top-sm) translateX(0);
				padding-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
				padding-right: ($grid-gutter-width / 2);

				.post__background-image {
					right: 0;
					left: inherit;
				}
			}

			&.is-right-aligned {
				flex: 0 0 100%;
				max-width: 100%;
				transform: translateY($offset-top-sm);
				padding-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
				padding-right: ($grid-gutter-width / 2);

				.post__background-image {
					left: 0;
					right: inherit;
				}
			}
		}

		&.has-offset-top {
			transform: translateY(calc(#{$offset-top-sm} + (var(--ty)) )) translateX(calc(#{$grid-gutter-width} / 2));
		}

		&.has-offset-bottom {
			margin-top: 0;
			transform: translateY(calc(#{$offset-bottom-sm} + (var(--ty)))) translateX(0);
		}

		&.has-offset-base {
			transform: translateY($offset-top-sm);
		}
	}
}
@include media-breakpoint-up(md) {
	.post {
		padding: $post-inner-padding-md;
		margin: 0;
		flex: 0 0 calc(50% - (#{$grid-gutter-width} / 4));
		max-width: 50%;

		.post__thumbnail {
			margin-bottom: 2vw;

			img {
				height: 16vw;
				max-height: 254px;
			}
		}

		.post__wrapper {
			.post__title {
				margin-bottom: $spacer * 2;

				a {
					span {
						font-size: $font-post-fullscreen-md;
						line-height: calc(#{$font-post-fullscreen-md} * 1.1);
					}
				}
			}

			.post__excerpt {
				font-size: $font-size--100;
				margin-bottom: $spacer * 1;
			}
		}

		&.has-content-image {
			.post__wrapper {
				.post__author,
				.post__excerpt {
					margin-left: auto;
					margin-right: auto;
				}

				.post__author,
				.post__excerpt,
				.post__title {
					text-align: center;
				}

				.post__title {
					text-align: center;
					margin-bottom: ($spacer * 1);

					a {
						span {
							font-size: $font-post-base-md;
							line-height: $line-height-post-base-md;
						}
					}
				}

				.post__excerpt {
					margin-bottom: $spacer * 0.5;
				}
			}
		}

		&.is-fullscreen {
			min-height: 360px;
			.post__wrapper {
				max-width: 70%;

				.post__title {
					margin-bottom: ($spacer * 2.5);

					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 2);
							font-size: $font-post-fullscreen-md;
							line-height: $line-height-post-fullscreen-md;
						}
					}
				}
			}

			&.is-left-aligned {
				flex: 0 0 calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-md}) + (#{$grid-gutter-width} / 2));
				max-width: calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-md}) + (#{$grid-gutter-width} / 2));
				padding-left: calc(#{$container-outer-padding-md} + (#{$grid-gutter-width} / 2));
				transform: translateY($offset-top-md) translateX(calc(#{$container-outer-padding-md} * -1));
				background-position: top right;
				background-size: contain;
			}

			&.is-right-aligned {
				flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
				max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
				margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
				transform: translateY($offset-top-md);
				padding-left: 8.33333%;
				background-position: top left;
				background-size: contain;
			}
		}

		&.has-offset-top {
			transform: translateY(calc(#{$offset-top-md} + (var(--ty)))) translateX(calc(#{$grid-gutter-width} / 2));
		}

		&.has-offset-bottom {
			margin-top: 0;
			transform: translateY(calc(#{$offset-bottom-md} + (var(--ty)))) translateX(0);
		}

		&.has-offset-base {
			transform: translateY($offset-top-md);
		}
	}
}
@include media-breakpoint-up(lg) {
	.post {
		padding: $post-inner-padding-lg;

		.post__thumbnail {
			margin-bottom: 3vw;

			img {
				height: 16vw;
				max-height: 254px;
			}
		}

		.post__wrapper {
			.post__title {
				margin-bottom: $spacer * 2;

				a {
					span {
						font-size: $font-post-fullscreen-lg;
						line-height: calc(#{$font-post-fullscreen-lg} * 1.2);
					}
				}
			}

			.post__excerpt {
				font-size: $font-size--175;
				margin-bottom: $spacer * 1;
			}
		}

		&.has-content-image {
			.post__wrapper {
				.post__author,
				.post__excerpt {
					margin-left: auto;
					margin-right: auto;
				}

				.post__title {
					margin-bottom: ($spacer * 1.5);

					a {
						span {
							font-size: $font-post-base-lg;
							line-height: $line-height-post-base-lg;
						}
					}
				}

				.post__excerpt {
					margin-bottom: $spacer * 1;
				}
			}
		}

		&.is-fullscreen {
			.post__wrapper {
				max-width: 70%;

				.post__title {
					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 2);
							font-size: $font-post-fullscreen-lg;
							line-height: $line-height-post-fullscreen-lg;
						}
					}
				}

				.post__excerpt {
					display: -webkit-box !important;
				}
			}

			&.is-left-aligned {
				flex: 0 0 calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-lg}) + (#{$grid-gutter-width} / 2));
				max-width: calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-lg}) + (#{$grid-gutter-width} / 2));
				transform: translateY($offset-top-lg) translateX(calc(#{$container-outer-padding-lg} * -1));
				padding-left: calc(8.33333% + #{$container-outer-padding-lg} + (#{$grid-gutter-width} / 2));
			}

			&.is-right-aligned {
				flex: 0 0 calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
				max-width: calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
				margin-left: calc((8.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
				transform: translateY($offset-top-lg) translateX(0);
				padding-left: 8.33333%;
			}
		}

		&.has-offset-top {
			transform: translateY(calc(#{$offset-top-lg} + calc(var(--ty)  * #{$offset-factor-lg}))) translateX(calc(#{$grid-gutter-width} / 2));
		}

		&.has-offset-bottom {
			transform: translateY(calc(#{$offset-bottom-lg} + calc(var(--ty) * #{$offset-factor-lg}))) translateX(0);
		}

		&.has-offset-base {
			transform: translateY($offset-top-lg);
		}
	}
}
@include media-breakpoint-up(xl) {
	.post {
		padding: $post-inner-padding-xl;

		.post__thumbnail {
			img {
				height: 18vw;
				max-height: 460px;
			}
		}

		&.is-fullscreen {
			&.is-left-aligned {
				flex: 0 0 calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) + (#{$grid-gutter-width} / 2));
				max-width: calc((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) + (#{$grid-gutter-width} / 2));
				transform: translateY($offset-top-xl) translateX(calc(#{$container-outer-padding-xl} * -1));
			}

			&.is-right-aligned {
				flex: 0 0 calc(((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
				max-width: calc(((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
				margin-left: calc((8.33333% + (#{$grid-gutter-width} / 2)));
				transform: translateY($offset-top-xl);
			}
		}

		&.has-offset-top {
			transform: translateY(calc(#{$offset-top-xl} + calc(var(--ty) * #{$offset-factor-xl}))) translateX(calc(#{$grid-gutter-width} / 2));
		}

		&.has-offset-bottom {
			transform: translateY(calc(#{$offset-bottom-xl} + calc(var(--ty) * #{$offset-factor-xl}))) translateX(0);
		}

		&.has-offset-base {
			transform: translateY($offset-top-xl);
		}
	}
}
@include media-breakpoint-up(xxl) {
	.post {
		.post__thumbnail {
			img {
				height: 14vw;
				max-height: 380px;
			}
		}

		.post__wrapper {
			.post__title {
				margin-bottom: $spacer * 2;

				a {
					span {
						font-size: $font-post-fullscreen-xxl;
						line-height: 1.1;
					}
				}
			}
		}

		&.has-content-image {
			.post__wrapper {
				.post__title {
					margin-bottom: ($spacer * 1.5);

					a {
						span {
							font-size: $font-post-base-xxl;
							line-height: $line-height-post-base-xxl;
						}
					}
				}
			}
		}

		&.is-fullscreen {
			.post__wrapper {
				.post__title {
					a {
						span {
							font-size: $font-post-fullscreen-xxl;
							line-height: $line-height-post-fullscreen-xxl;
						}
					}
				}
			}

			&.is-left-aligned {
				flex: 0 0 calc((91.66667% - #{$grid-gutter-width}));
				max-width: calc((91.66667% - #{$grid-gutter-width}));
				transform: translateY($offset-top-xl) translateX(calc((#{$grid-gutter-width} / 2)));
			}

			&.is-right-aligned {
				flex: 0 0 calc(((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) - #{$grid-gutter-width}));
				max-width: calc(((91.66667% - #{$grid-gutter-width}) + (#{$container-outer-padding-xl}) - #{$grid-gutter-width}));
				margin-left: calc((8.33333% + (#{$grid-gutter-width} / 2)));
				transform: translateY($offset-top-xl);
			}
		}
	}
}

// Edge styles
@supports (-ms-ime-align: auto) {
	.post {
		&.has-offset-top {
			transform: translateY(calc(#{$offset-top})) translateZ(0) !important;
		}
		&.has-offset-bottom {
			margin-top: ($spacer * 2);
			transform: translateY(calc(#{$offset-bottom})) translateZ(0) !important;
		}
		&.has-offset-base {
			transform: translateY($offset-top) translateZ(0) !important;
		}
	}

	@include media-breakpoint-up(sm) {
		.post {
			&.has-offset-top {
				transform: translateY(calc(#{$offset-top-sm} * 1 )) translateX(calc(#{$grid-gutter-width} / 2)) !important;
			}
			&.has-offset-bottom {
				margin-top: 0;
				transform: translateY(calc(#{$offset-bottom-sm} * 1)) translateX(0) !important;
			}
			&.has-offset-base {
				transform: translateY($offset-top-sm) !important;
			}
			&.is-fullscreen {
				&.is-left-aligned {
					transform: translateY(calc(#{$offset-top-sm} * #{$offset-factor-ms})) translateX(0) !important;
				}
				&.is-right-aligned {
					transform: translateY(calc(#{$offset-top-sm} * #{$offset-factor-ms})) !important;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.post {
			&.has-offset-top {
				transform: translateY(calc(#{$offset-top-md} * 1)) translateX(calc(#{$grid-gutter-width} / 2)) !important;
			}
			&.has-offset-bottom {
				margin-top: 0;
				transform: translateY(calc(#{$offset-bottom-md} * 1)) translateX(0) !important;
			}
			&.has-offset-base {
				transform: translateY($offset-top-md) !important;
			}
			&.is-fullscreen {
				&.is-left-aligned {
					transform: translateY(calc(#{$offset-top-md} * #{$offset-factor-ms})) translateX(calc(#{$container-outer-padding-md} * -1)) !important;
				}
				&.is-right-aligned {
					transform: translateY(calc(#{$offset-top-md} * #{$offset-factor-ms})) !important;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.post {
			&.has-offset-top {
				transform: translateY(calc(#{$offset-top-lg} * 1)) translateX(calc(#{$grid-gutter-width} / 2)) !important;
			}
			&.has-offset-bottom {
				transform: translateY(calc(#{$offset-bottom-lg} * 1)) translateX(0) !important;
			}
			&.has-offset-base {
				transform: translateY($offset-top-lg) !important;
			}
			&.is-fullscreen {
				&.is-left-aligned {
					transform: translateY(calc(#{$offset-top-lg} * #{$offset-factor-ms})) translateX(calc(#{$container-outer-padding-lg} * -1)) !important;
				}

				&.is-right-aligned {
					transform: translateY(calc(#{$offset-top-lg} * #{$offset-factor-ms})) translateX($container-outer-padding-lg) !important;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.post {
			&.has-offset-top {
				transform: translateY(calc(#{$offset-top-xl} * 1)) translateX(calc(#{$grid-gutter-width} / 2)) !important;
			}

			&.has-offset-bottom {
				transform: translateY(calc(#{$offset-bottom-xl} * 1)) translateX(0) !important;
			}

			&.has-offset-base {
				// transform: translateY($offset-top-xl);
				transform: none !important;
			}
			&.is-fullscreen {
				&.is-left-aligned {
					transform: translateY(calc(#{$offset-top-xl} * #{$offset-factor-ms})) translateX(calc(#{$container-outer-padding-xl} * -1)) !important;
				}

				&.is-right-aligned {
					transform: translateY(calc(#{$offset-top-xl} * #{$offset-factor-ms})) !important;
				}
			}
		}
	}

	@include media-breakpoint-up(xxl) {
		.post {
			&.is-fullscreen {
				&.is-left-aligned {
					transform: translateY(calc(#{$offset-top-xl} * #{$offset-factor-ms})) translateX(calc((#{$grid-gutter-width} / 2))) !important;
				}
				&.is-right-aligned {
					transform: translateY(calc(#{$offset-top-xl} * #{$offset-factor-ms})) !important;
				}
			}
		}
	}
}
