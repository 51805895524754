.grid-feed{
  width: 100%;
	height: auto;

	@extend .container-fluid;

  .grid-feed__wrapper{
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .grid-feed__body{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &.grid-feed--event{
    margin-top: $header-height-xs;
  }

  &.grid-feed-offset-ms {
    transform: translateY($offset-top);
  }

}

@include media-breakpoint-up(sm) {
  .grid-feed{

    &.grid-feed--event{
      margin-top: $header-height-sm;
    }
  }
}

@include media-breakpoint-up(md) {
  .grid-feed{

    &.grid-feed--event{
      margin-top: $header-height-md;
    }

    .grid-feed__wrapper{
      margin-left: -25px;
      margin-right: -25px;
    }

    &.grid-feed-offset-ms {
      transform: translateY($offset-top-md);
    }
  }
}

@include media-breakpoint-up(lg) {
  .grid-feed{

    &.grid-feed--event{
    margin-top: $header-height-lg;
    }

    &.grid-feed-offset-ms {
      transform: translateY($offset-top-lg);
    }
  }
}

@include media-breakpoint-up(xl) {
  .grid-feed{

    &.grid-feed--event{
      margin-top: $header-height-xl;
    }

    &.grid-feed-offset-ms {
      transform: translateY($offset-top-xl);
    }
  }
}
