$widget-slider-padding: ($grid-gutter-width / 2);
$section-event-offset: $grid-gutter-width;

.section-event {
	width: 100%;
	height: auto;
	z-index: 0;
	position: relative;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-event__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-event__container {
			padding: 0 0 ($spacer * 2) 0;
			width: 100%;

			.section-event__header {
				width: 100%;
				position: relative;
				z-index: 2;
				text-align: center;
				padding-left: calc(#{$container-outer-padding});
				padding-right: calc(#{$container-outer-padding});
				transform: translateY(-15px);

				.section-event__title {
					background: $danger;
					color: $white;
					display: inline-block;
					font-family: $font-family-monospace;
					font-size: 16px;
					letter-spacing: 0.25px;
					text-decoration: none;
					margin-bottom: 0;
					padding: $spacer ($spacer * 2);
				}
			}

			.section-event__body {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 2;
				flex-wrap: wrap;
				padding-left: calc(#{$container-outer-padding});
				padding-right: calc(#{$container-outer-padding});

				.section-event__body-wrapper {
					width: 100%;

					.section-event__body-divider {
						display: flex;
						flex-direction: column;
						width: 100%;
						text-align: left;
						line-height: 1.6;
						font-family: $font-family-monospace;
						letter-spacing: 0.25px;
						position: relative;
						padding: $spacer 0 ($spacer * 4) 0;

						&:before {
							content: '';
							position: absolute;
							top: -30px;
							left: -1px;
							width: 2px;
							height: 100%;
							background: $secondary;
						}

						&:first-child {
							&:before {
								top: 0;
							}
						}
					}

					.section-event__body-item {
						display: flex;
						flex-direction: column;
					}
				}
			}

			.section-event__container-back {
				background: $secondary;
				width: 100%;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-event {
		margin-bottom: $article-bottom-margin-sm;
		// transform: translateY($offset-top-sm);
		.section-event__wrapper {
			.section-event__container {
				padding: ($spacer * 3) 0 ($spacer * 3) 0;

				.section-event__header {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);
					transform: none;
					text-align: left;
					margin-bottom: $spacer * 3;
				}

				.section-event__body {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);
					justify-content: inherit;
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-event {
		margin-bottom: $article-bottom-margin-md;

		.section-event__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-event__container {
				padding: ($spacer * 6) 0;

				.section-event__header {
					flex: 0 0 91.66667%;
					max-width: 91.66667;
					margin-left: 16.66667%;
					padding: 0 ($grid-gutter-width / 2);
					justify-content: center;
					display: flex;
					text-align: center;
				}

				.section-event__body {
					flex: 0 0 91.66667;
					max-width: 91.66667;
					margin-left: 16.66667%;
					padding: 0 ($grid-gutter-width / 2);

					.section-event__body-wrapper {
						.section-event__body-divider {
							text-align: center;
							padding-top: calc((#{$dialog-divider-height} / 2) + (#{$spacer} * 3));
							padding-bottom: 0;
							margin-bottom: ($spacer * 3);

							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: calc(50% - 1px);
								width: 2px;
								height: $dialog-divider-height / 2;
								background: $white;
							}
						}
					}
				}

				.section-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-event {
		margin-bottom: $article-bottom-margin-lg;
		// transform: translateY($offset-top-lg);
		.section-event__wrapper {
			.section-event__container {
				flex: 0 0 100%;
				max-width: 100%;
				padding: ($spacer * 10) 0;

				.section-event__header {
					flex: 0 0 75%;
					max-width: 75%;
					margin-left: 16.66667%;
					padding: 0 ($grid-gutter-width / 2);

					.section-event__title {
						line-height: 1.4;
						margin-bottom: ($spacer * 3);

						span {
							font-size: 72px;
							padding: ($spacer * 0.3) ($spacer * 2);
						}
					}
				}

				.section-event__body {
					flex: 0 0 75%;
					max-width: 75%;
					margin-left: 16.66667%;
					padding: 0 ($grid-gutter-width / 2);

					.section-event__body-wrapper {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}

				.section-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-event {
		// transform: translateY($offset-top-xl);
		.section-event__wrapper {
			.section-event__container {
				.section-event__header {
					flex: 0 0 75%;
					max-width: 75%;
					margin-left: 16.66667%;
				}

				.section-event__body {
					flex: 0 0 75%;
					max-width: 75%;
					margin-left: 16.66667%;
					padding: 0 ($grid-gutter-width / 2);

					.section-event__body-wrapper {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}

				.section-event__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-event {
		// transform: translateY($offset-top-xl);
		.section-event__wrapper {
			.section-event__container {
				.section-event__container-back {
					flex: 0 0 calc((91.66667% - (#{$grid-gutter-width})));
					max-width: calc((91.66667% - (#{$grid-gutter-width})));
					margin-left: calc((8.33333% + #{$grid-gutter-width}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
