$feauted-image-offset: $grid-gutter-width;
$feauted-image-offset-sm: $grid-gutter-width;
$feauted-image-offset-md: $grid-gutter-width;
$feauted-image-offset-lg: $grid-gutter-width;
$feauted-image-offset-xl: $grid-gutter-width;
$feauted-image-offset-xxl: $grid-gutter-width;

.section-person {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-person__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-person__container {
			width: 100%;

			.section-person__container-item {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				z-index: 2;
				padding: ($spacer * 5) $container-outer-padding;

				.section-person__detail {
					height: auto;
					max-width: 80%;
					margin-bottom: ($spacer * 3);

					.person {
						.person__detail {
							bottom: -10px;
							left: -10px;

							.person__name {
								text-decoration: none;

								&:hover {
									text-decoration: underline;
								}
							}
						}

						.person__image {
							opacity: 1;
						}
					}
				}

				.section-person__excerpt {
					max-width: 80%;

					p {
						margin-bottom: 0;
						background-color: $white;
						padding: ($spacer * 0.8) ($spacer);
						box-decoration-break: clone;
						-webkit-box-decoration-break: clone;
						box-shadow: $box-shadow--100;
						display: inline;
						margin-bottom: 0;
						line-height: 2.25;
					}
				}
			}

			.section-person__container-back {
				width: 100%;
				background: $secondary;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-person {
		margin-bottom: $article-bottom-margin-sm;

		.section-person__wrapper {
			.section-person__container {
				.section-person__container-item {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding: ($spacer * 6) ($grid-gutter-width / 2);

					.section-person__detail {
						max-height: none;
						max-width: 70%;
						margin-bottom: ($spacer * 5);
					}

					.section-person__excerpt {
						max-width: 70%;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-person {
		margin-bottom: $article-bottom-margin-md;

		.section-person__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-person__container {
				.section-person__container-item {
					flex: 0 0 100%;
					max-width: 100%;
					padding: ($spacer * 6) 0;
					margin: 0;
					flex-direction: row;
					align-items: center;

					.section-person__detail {
						flex: 0 0 calc(41.66667% + #{$feauted-image-offset-md});
						max-width: calc(41.66667% + #{$feauted-image-offset-md});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 8.33333%;
						transform: translateX(-$feauted-image-offset-md);
						align-self: center;
						margin-bottom: 0;
					}

					.section-person__excerpt {
						flex: 0 0 41.66667%;
						max-width: 41.66667%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-md);
						align-self: center;

						p {
							line-height: 2;
						}
					}
				}

				.section-person__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-person {
		margin-bottom: $article-bottom-margin-lg;

		.section-person__wrapper {
			.section-person__container {
				padding: 0;
				flex: 0 0 100%;
				max-width: 100%;

				.section-person__container-item {
					flex: 0 0 100%;
					max-width: 100%;
					padding: ($spacer * 10) 0;

					.section-person__detail {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-lg});
						max-width: calc(33.33333% + #{$feauted-image-offset-lg});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
						transform: translateX(-$feauted-image-offset-lg);
					}

					.section-person__excerpt {
						flex: 0 0 41.66667%;
						max-width: 41.66667%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-lg);

						p {
							line-height: 2;
						}

						blockquote {
							p {
								font-size: $font-size--175;
								line-height: 2.1;
							}
						}
					}
				}

				.section-person__container-back {
					flex: 0 0 calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					max-width: calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					margin-left: calc((16.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-person {
		.section-person__wrapper {
			.section-person__container {
				.section-person__container-item {
					.section-person__detail {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-xl});
						max-width: calc(33.33333% + #{$feauted-image-offset-xl});
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
						transform: translateX(-$feauted-image-offset-xl);
					}

					.section-person__excerpt {
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(-$feauted-image-offset-xl);
					}
				}

				.section-person__container-back {
					flex: 0 0 calc((83.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					max-width: calc((83.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					margin-left: calc((16.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-person {
		.section-person__wrapper {
			.section-person__container {
				.section-person__container-item {
					.section-person__detail {
						flex: 0 0 calc(33.33333% + #{$feauted-image-offset-xl});
						max-width: calc(33.33333% + #{$feauted-image-offset-xl});
					}

					.section-person__excerpt {
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
					}
				}

				.section-person__container-back {
					flex: 0 0 calc((83.33333% - (#{$grid-gutter-width})));
					max-width: calc((83.33333% - (#{$grid-gutter-width})));
					margin-left: calc((16.66667% + (#{$grid-gutter-width} / 2)));
				}
			}
		}
	}
}
