.katana {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-family-monospace;
		line-height: 1;
		margin-bottom: $text-bottom-margin;

		strong{
			font-weight: normal;
		}
	}

	h1,
	.headline-size-h1{
		font-size: 32px;
		line-height: 1.2;
	}

	h2,
	.headline-size-h2{
		font-size: 28px;
		line-height: 1.2;
	}

	h3,
	.headline-size-h3{
		font-size: 22px;
		line-height: 1.4;
	}

	h4,
	.headline-size-h4{
		font-size: $font-size--150;
		line-height: 1.4;
	}

	h5,
	.headline-size-h5,
	h6,
	.headline-size-h6{
		font-size: $font-size--100;
		line-height: 1.4;
	}
@include media-breakpoint-up(sm) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: $text-bottom-margin-sm;
	}
}
	@include media-breakpoint-up(md) {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: $text-bottom-margin-md;
		}

		h1,
		.headline-size-h1{
			font-size: 38px;
			line-height: 1.2;
		}

		h2,
		.headline-size-h2{
			font-size: 30px;
			line-height: 1.2;
		}

		h3,
		.headline-size-h3{
			font-size: 28px;
			line-height: 1.4;
		}

		h4,
		.headline-size-h4{
			font-size: 24px;
			line-height: 1.4;
		}

		h5,
		.headline-size-h5,
		h6,
		.headline-size-h6{
			font-size: $font-size--175;
			line-height: 1.4;
		}
	}

	@include media-breakpoint-up(lg) {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: $text-bottom-margin-lg;
		}

		h1,
		.headline-size-h1{
			font-size: 46px;
			line-height: 1.2;
		}

		h2,
		.headline-size-h2{
			font-size: 32px;
			line-height: 1.2;
		}

		h3,
		.headline-size-h3{
			font-size: 28px;
			line-height: 1.4;
		}
	}

	@include media-breakpoint-up(xl) {

		h1,
		.headline-size-h1{
			font-size: 64px;
			line-height: 1.2;
		}

		h2,
		.headline-size-h2{
			font-size: 40px;
			line-height: 1.2;
		}

		h3,
		.headline-size-h3{
			font-size: 32px;
			line-height: 1.4;
		}

		h4,
		.headline-size-h4{
			font-size: 28px;
			line-height: 1.4;
		}
	}
}
