.katana {
	.aligncenter,
	.alignleft,
	.alignright,
	.size-full {
		float: none;
		margin-left: auto;
		margin-right: auto;
		display: block;
		text-align: left;
		margin: $text-bottom-margin auto;
		height: auto;
		width: 100%;
		max-width: 100%;
	}

	.alignnone {
		vertical-align: middle;
		border-style: none;
		max-width: 100%;
		height: auto;
		margin: 0;
	}

	.size-full {
		width: 100%!important;
		max-width: 100%!important;
	}

	.wp-caption{
		width: 100%!important;
		max-width: 100%!important;
	}
}
@include media-breakpoint-up(sm) {
	.katana {
		.aligncenter,
		.alignleft,
		.alignright,
		.size-full {
			margin: $text-bottom-margin-sm auto;
		}
	}
}
@include media-breakpoint-up(md) {
	.katana {
		.aligncenter {
			margin: $text-bottom-margin-md auto;
			text-align: center;
		}

		.size-full {
			margin: $text-bottom-margin-md 0;
		}

		.alignleft {
			float: left;
			margin-top: $spacer * 1;
			margin-right: $spacer * 3;
			margin-bottom: $spacer * 1;
			text-align: inherit;
			width: 50%!important;
			max-width: 50%!important;
		}

		.alignright {
			float: right;
			margin-top: $spacer * 1;
			margin-left: $spacer * 3;
			margin-bottom: $spacer * 1;
			text-align: inherit;
			width: 50%!important;
			max-width: 50%!important;
		}

		.wp-caption{
			width: 100%!important;
			max-width: 100%!important;

			&.alignright{
				width: 50%!important;
				max-width: 50%!important;
			}

			&.alignleft{
				width: 50%!important;
				max-width: 50%!important;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.katana {
		.aligncenter {
			margin: $text-bottom-margin-lg auto;
		}
		.size-full {
			margin: $text-bottom-margin-lg 0;
		}
	}
}
