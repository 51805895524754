.header {
  // navbar
  .navbar {
    transition: all 0.15s ease-in;
    padding: 0;
    height: $header-height-xs;

    .container-fluid {
      padding: 0 $container-outer-padding;
      align-items: center;

      button {
        border-radius: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: transparent;

        svg {
          transition: all 0.15s ease-in;
          width: 18px;
          fill: $primary;
          transform: translateY(0px);
        }

        &:focus,
        &:hover {
          border: none;
          box-shadow: none;
          outline: none;
          cursor: pointer;
        }
      }

      .navbar-brand {
        display: inline-flex;
        align-items: center;
        padding: 0;
        margin: 0;

        .logo {
          display: inline-flex;
          align-items: center;
          max-height: 32px;

          img {
            transition: all 0.15s ease-in;
            max-height: 32px;
            width: $logo-width-xs;
          }
        }

        .logo-award {
          display: inline-flex;
          align-items: center;
          max-height: 32px;
          margin-left: $spacer * 1;

          img {
            transition: all 0.15s ease-in;
            max-height: 32px;
            object-fit: contain;
            max-width: 64px;
          }
        }
      }

      .navbar-toggler {
        z-index: 9999;

        svg {
          &.is-cross g,
          path {
            transition: all 0.15s ease-in;
          }

          &.is-cross #hamburger-top {
            transform-origin: center center;
            transform: rotate(45deg) translateY(45%);
            fill: $white;
          }

          &.is-cross #hamburger-middle {
            transform-origin: center center;
            opacity: 0;
            transform: scaleX(0.1);
            fill: $white;
          }

          &.is-cross #hamburger-bottom {
            transform-origin: center center;
            transform: rotate(-45deg) translateY(-45%);
            fill: $white;
          }
        }
      }

      .navbar-collapse {
        justify-content: flex-start;

        &.offcanvas-collapse {
          position: fixed;
          top: 0;
          left: inherit;
          right: 0;
          width: $navbar-offcanvas-width;
          height: 100vh;
          padding: 0;
          overflow-y: auto;
          background-color: $secondary;
          transition: all 0.3s ease-in-out;
          -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
          opacity: 0.1;
          z-index: 1030;
          align-items: flex-start;

          &.open {
            transform: translateX($navbar-offcanvas-width) translateY(0%);
            opacity: 1;
          }

          .navbar-nav__wrapper {
            margin: 60px auto 0;
            width: auto;

            .navbar-nav {
              margin: $header-height-xs 0 0;
              padding: 0;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;

              .nav-item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2px;
                box-shadow: $box-shadow--100;

                .nav-link {
                  padding: 0;
                  color: $primary;
                  font-family: $font-family-monospace;
                  font-size: 24px;
                  text-decoration: none;
                  padding: ($spacer * 1) ($spacer * 1);
                  background: $white;

                  &:hover {
                    border: none;
                    outline: none;
                    color: $primary;
                    text-decoration: underline;
                  }

                  &:focus {
                    box-shadow: none;
                    outline: none;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.open {
      .container-fluid {
        .navbar-toggler {
          transform: translateX($navbar-offcanvas-width);
        }

        .navbar-brand {
          visibility: hidden;
        }
      }
    }
  }
  // shrinked
  &.shrinked {
    transition: all 0.15s ease-in;
    height: $header-height-shrinked-xs;

    .navbar {
      height: $header-height-shrinked-xs;
      padding: 0;

      .container-fluid {
        button {
          svg {
            transition: all 0.15s ease-in;
            width: 16px;
          }
        }

        .searchbar-collapse {
          height: $header-height-shrinked-xs;
        }

        .navbar-brand {
          .logo {
            img {
              transition: all 0.15s ease-in;
              width: $logo-width-shrinked-xs;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(sm) {
  .header {
    .navbar {
      height: $header-height-sm;

      &.open {
        .container-fluid {
          .navbar-toggler {
            transform: translateX(0px);

            svg {
              &.is-cross g,
              path {
                transition: all 0.15s ease-in;
              }

              &.is-cross #hamburger-top {
                transform-origin: center center;
                transform: rotate(45deg) translateY(45%);
                fill: $black;
              }

              &.is-cross #hamburger-middle {
                transform-origin: center center;
                opacity: 0;
                transform: scaleX(0.1);
                fill: $black;
              }

              &.is-cross #hamburger-bottom {
                transform-origin: center center;
                transform: rotate(-45deg) translateY(-45%);
                fill: $black;
              }
            }
          }
        }
      }

      .container-fluid {
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2);

        .navbar-brand {
          .logo {
            max-height: 40px;

            img {
              max-height: 40px;
              width: $logo-width-sm;
            }
          }
          .logo-award {
            max-height: 40px;
            margin-left: $spacer * 10;

            img {
              max-height: 40px;
              max-width: unset;
            }
          }
        }

        .navbar-collapse {
          &.offcanvas-collapse {
            width: $navbar-offcanvas-width-sm;

            &.open {
              transform: translateX($navbar-offcanvas-width-sm) translateY(0%);
            }

            .navbar-nav__wrapper {
              margin: 0;

              .navbar-nav {
                margin-top: $header-height-sm;

                .nav-item {
                  .nav-link {
                    padding: ($spacer * 1) ($spacer * 1.5);
                    font-size: 26px;
                    line-height: normal;
                  }
                }
              }
            }
          }
        }
      }
    }
    // shrinked
    &.shrinked {
      .navbar {
        height: $header-height-shrinked-sm;

        .container-fluid {
          .navbar-brand {
            .logo {
              img {
                width: $logo-width-shrinked-xs;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .header {
    .navbar {
      height: $header-height-md;

      .container-fluid {
        padding-right: $container-outer-padding-md;
        padding-left: $container-outer-padding-md;

        .navbar-brand {
          .logo {
            max-height: 40px;

            img {
              max-height: 40px;
              width: $logo-width-md;
            }
          }
          .logo-award {
            max-height: 40px;

            img {
              max-height: 40px;
            }
          }
        }

        .navbar-collapse {
          &.offcanvas-collapse {
            width: $navbar-offcanvas-width-md;

            &.open {
              transform: translateX($navbar-offcanvas-width-md) translateY(0%);
            }

            .navbar-nav__wrapper {
              margin: 0;

              .navbar-nav {
                margin-top: $header-height-md;

                .nav-item {
                  .nav-link {
                    padding: ($spacer * 1.2) ($spacer * 1.5);
                    font-size: 32px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }

        button {
          svg {
            width: 20px;
          }
        }
      }
    }

    &.shrinked {
      .navbar {
        height: $header-height-shrinked-md;

        .container-fluid {
          .navbar-brand {
            .logo {
              img {
                width: $logo-width-shrinked-md;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .header {
    .navbar {
      height: 100%;
      padding-bottom: $spacer * 3;
      align-items: flex-end;

      .container-fluid {
        padding-right: $container-outer-padding-lg;
        padding-left: $container-outer-padding-lg;

        .navbar-brand {
          .logo {
            max-height: 48px;

            img {
              width: $logo-width-lg;
              max-height: 48px;
            }
          }
          .logo-award {
            max-height: 48px;

            margin-left: $spacer * 12;

            img {
              max-height: 48px;
            }
          }
        }

        .navbar-collapse {
          justify-content: flex-start;

          &.offcanvas-collapse {
            width: $navbar-offcanvas-width-lg;

            &.open {
              transform: translateX($navbar-offcanvas-width-lg) translateY(0%);
            }

            .navbar-nav__wrapper {
              margin: 0;

              .navbar-nav {
                margin-top: $header-height-lg;

                .nav-item {
                  margin-bottom: 3px;
                  .nav-link {
                    padding: ($spacer * 1.5) ($spacer * 2);
                    font-size: 36px;
                  }
                }
              }
            }
          }
        }
      }

      &.open {
        .container-fluid {
          .navbar-brand {
            visibility: visible;
            opacity: 0.2;
          }
        }
      }
    }

    &.shrinked {
      .navbar {
        height: 100%;
        padding-bottom: $spacer * 2;

        .container-fluid {
          .navbar-brand {
            .logo {
              img {
                width: $logo-width-shrinked-lg;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .header {
    .navbar {
      .container-fluid {
        padding-right: $container-outer-padding-xl;
        padding-left: $container-outer-padding-xl;

        .navbar-brand {
          .logo {
            max-height: 60px;

            img {
              width: $logo-width-xl;
              max-height: 60px;
            }
          }
          .logo-award {
            max-height: 60px;

            img {
              max-height: 60px;
            }
          }
        }

        .navbar-collapse {
          &.offcanvas-collapse {
            width: $navbar-offcanvas-width-xl;

            &.open {
              transform: translateX($navbar-offcanvas-width-xl) translateY(0%);
            }

            .navbar-nav__wrapper {
              margin: 0;

              .navbar-nav {
                margin-top: $header-height-xl;

                .nav-item {
                  .nav-link {
                    padding: ($spacer * 1.5) ($spacer * 2);
                    font-size: 42px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.shrinked {
      .navbar {
        height: $header-height-shrinked-xl;

        .container-fluid {
          .navbar-brand {
            .logo {
              img {
                width: $logo-width-shrinked-xl;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .header {
    .navbar {
      .container-fluid {
        .navbar-collapse {
          &.offcanvas-collapse {
            width: $navbar-offcanvas-width-xxl;

            &.open {
              transform: translateX($navbar-offcanvas-width-xxl) translateY(0%);
            }

            .navbar-nav__wrapper {
              .navbar-nav {
                .nav-item {
                  .nav-link {
                    padding: ($spacer * 1.5) ($spacer * 2.5);
                    font-size: 46px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
