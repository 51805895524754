//
// Color system
//
// stylelint-disable
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$custom-color-green:#C8FFB2;
$custom-color-pink: #FDEBED;
$custom-color-yellow: #FFE14F;
$custom-color-blue: #E8F3F8;
$custom-color-orange: #FFF6EA;
$custom-color-teal: #E4F3EF;

$primary: #1A1A1A;
$secondary: #10eef5;
// $secondary: #a98d4f;

$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        #FF4343;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$body-bg: $white;
$body-color: #2A2A2A;
