// Fonts

//@import url("http://hello.myfonts.net/count/36a5bd");

@font-face {
	font-family: 'OfficinaSerITCStd-Bold';
	src: 	url('/wp-content/themes/companions/assets/fonts/36A810_0_0.eot');
	src: 	url('/wp-content/themes/companions/assets/fonts/36A810_0_0.eot?#iefix') format('embedded-opentype'),
				url('/wp-content/themes/companions/assets/fonts/36A810_0_0.woff2') format('woff2'),
				url('/wp-content/themes/companions/assets/fonts/36A810_0_0.woff') format('woff'),
				url('/wp-content/themes/companions/assets/fonts/36A810_0_0.ttf') format('truetype');
	font-display: swap;
}

// Base font
@font-face {
	font-family: 'PalatinoLTPro-Medium';
	src: 	url('/wp-content/themes/companions/assets/fonts/36A5BD_1_0.eot');
	src: 	url('/wp-content/themes/companions/assets/fonts/36A5BD_1_0.eot?#iefix') format('embedded-opentype'),
				url('/wp-content/themes/companions/assets/fonts/36A5BD_1_0.woff2') format('woff2'),
				url('/wp-content/themes/companions/assets/fonts/36A5BD_1_0.woff') format('woff'),
				url('/wp-content/themes/companions/assets/fonts/36A5BD_1_0.ttf') format('truetype');
	font-display: swap;
}

$font-family-monospace: 'OfficinaSerITCStd-Bold', serif;
$font-family-base: 'PalatinoLTPro-Medium', serif; // stylelint-enable value-keyword-case

$font-size--50: 12px;
$font-size--75: 14px;
$font-size--100: 16px;
$font-size--150: 18px;
$font-size--175: 20px;

$font-post-base-xs:6.875vw;
$font-post-fullscreen-xs: 8.75vw;
$font-post-base-sm: 3.25vw;
$font-post-fullscreen-sm: 5vw;
$font-post-base-md: 3vw;
$font-post-fullscreen-md: 5vw;
$font-post-base-lg: 3vw;
$font-post-fullscreen-lg: 4.5vw;
$font-post-base-xxl: 46px;
$font-post-fullscreen-xxl: 70px;

$line-height-initial: 1.475em;
$line-height-post-base-xs:calc(#{$font-post-base-xs} * 1.1);
$line-height-post-fullscreen-xs: calc(#{$font-post-fullscreen-xs} * 1.5);
$line-height-post-base-sm: 1;
$line-height-post-fullscreen-sm: calc(#{$font-post-fullscreen-sm} * 1.7);
$line-height-post-base-md: calc(#{$font-post-base-md} * 1.2);
$line-height-post-fullscreen-md: calc(#{$font-post-fullscreen-md} * 1.55);
$line-height-post-base-lg: calc(#{$font-post-base-lg} * 1.25);
$line-height-post-fullscreen-lg: calc(#{$font-post-fullscreen-lg} * 1.5);
$line-height-post-base-xxl: 1.1;
$line-height-post-fullscreen-xxl: 1.4;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$article-bottom-margin: $spacer * 3;
$article-bottom-margin-sm: $spacer * 3;
$article-bottom-margin-md: $spacer * 4;
$article-bottom-margin-lg: $spacer * 4;

$text-bottom-margin: $spacer * 2;
$text-bottom-margin-sm: $spacer * 2;
$text-bottom-margin-md: $spacer * 2.5;
$text-bottom-margin-lg: $spacer * 2;

.katana{
	.lead{
		font-weight: bold;
		font-family: $font-family-base;
		line-height: $line-height-initial;
	}

	a {
		text-decoration: underline;
	}

	p,
	ul,
	ol{
		font-size: $font-size--100;
		line-height: $line-height-initial;
		margin-bottom: $text-bottom-margin;
	}

	ul,
	ol{
		padding-left: ($spacer * 2.5);
	}

	blockquote{
		margin-bottom: $text-bottom-margin;
		font-family: $font-family-monospace;
	}

	cite {
		display: block;
		margin-top: ($spacer);
		font-family: $font-family-monospace;
		font-size: $font-size--75;
		letter-spacing: 0.25px;
		font-style: normal;
	}

	figure{
		margin: 0;
	}

	figcaption{
		font-size: $font-size--75;
		font-style: italic;
		margin-top: $spacer * 1;
	}
}

@include media-breakpoint-up(sm) {
	.katana{
		p,
		ul,
		ol{
			margin-bottom: $text-bottom-margin-sm;
		}

		ul,
		ol{
			padding-left: ($spacer * 3);
		}

		blockquote{
			margin-bottom: $text-bottom-margin-sm;
		}
	}
}

@include media-breakpoint-up(md) {
	.katana{
		.lead{
			font-size: $font-size--175;
		}

		p,
		ul,
		ol{
			font-size: $font-size--175;
			margin-bottom: $text-bottom-margin-md;
		}

		ul,
		ol{
			padding-left: ($spacer * 4);
		}

		blockquote{
			margin-bottom: $text-bottom-margin-md;
		}
	}
}

@include media-breakpoint-up(lg) {
	.katana{
		p,
		ul,
		ol{
			margin-bottom: $text-bottom-margin-lg;
		}

		ul,
		ol{
			padding-left: ($spacer * 5);
		}

		blockquote{
			margin-bottom: $text-bottom-margin-lg;
		}
	}
}
