$section-gallery-height: 200px;
$section-gallery-height-sm: 260px;
$section-gallery-height-md: 400px;
$section-gallery-height-lg: 480px;
$section-gallery-height-xl: 640px;
$section-gallery-height-xxl: 720px;

.section-gallery {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-gallery__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-gallery__body {
			padding-left: $container-outer-padding;
			padding-right: $container-outer-padding;
			width: 100%;
			height: $section-gallery-height;

			.slider {
				.slick-list {
					.slider__slide {
						margin: 0 ($spacer * 2);
						height: $section-gallery-height;

						img {
							width: auto;
							object-fit: contain;
							height: $section-gallery-height;
						}
					}
				}
				.slick-prev {
					left: calc((60px / 3) * -1);
					padding: 0;
					width: 60px;
				}

				.slick-next {
					right: calc((60px / 3) * -1);
					padding: 0;
					width: 60px;
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-gallery {
		margin-bottom: $article-bottom-margin-sm;

		.section-gallery__wrapper {
			.section-gallery__body {
				.slider {
					.slick-list {
						.slider__slide {
							margin: 0 ($spacer * 2) 0 0;
							height: $section-gallery-height;
						}
					}
				}
				flex: 0 0 83.33333%;
				max-width: 83.33333%;
				margin-left: 8.33333%;
				padding-left: ($grid-gutter-width / 2);
				padding-right: ($grid-gutter-width / 2);
				height: $section-gallery-height-sm;

				.slider {
					.slick-list {
						.slider__slide {
							height: $section-gallery-height-sm;

							img {
								height: $section-gallery-height-sm;
							}
						}
					}
					.slick-prev {
						left: calc(((#{$slider-arrow-widht} / 2) * -1));
						padding: ($spacer);
						width: 80px;
					}

					.slick-next {
						right: calc((#{$slider-arrow-widht} / 2) * -1);
						padding: ($spacer);
						width: 80px;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-gallery {
		margin-bottom: $article-bottom-margin-md;

		.section-gallery__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-gallery__body {
				height: $section-gallery-height-md;

				.slider {
					.slick-list {
						.slider__slide {
							margin-right: $grid-gutter-width;
							height: $section-gallery-height-md;

							img {
								height: $section-gallery-height-md;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-gallery {
		margin-bottom: $article-bottom-margin-lg;

		.section-gallery__wrapper {
			.section-gallery__body {
				flex: 0 0 calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
				max-width: calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
				margin-left: calc(16.66667% + (#{$grid-gutter-width} / 2));
				height: $section-gallery-height-lg;
				padding: 0;

				.slider {
					.slick-list {
						.slider__slide {
							height: $section-gallery-height-lg;

							img {
								height: $section-gallery-height-lg;
							}
						}
					}

					.slick-next{
						background: $white;
						height: $slider-arrow-widht;
						top: calc(50% - (#{$slider-arrow-widht} / 2));
						box-shadow: $box-shadow--100;
					}

					.slick-next {
						right: calc(((16.66667% + (#{$grid-gutter-width} / 2)) + #{$container-outer-padding-lg}) + 16.6666666667px + (#{$slider-arrow-widht} / 2 * -1));
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-gallery {
		.section-gallery__wrapper {
			.section-gallery__body {
				height: $section-gallery-height-xl;

				.slider {
					.slick-list {
						.slider__slide {
							height: $section-gallery-height-xl;

							img {
								height: $section-gallery-height-xl;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-gallery {
		.section-gallery__wrapper {
			.section-gallery__body {
				flex: 0 0 calc((83.33333% - (#{$grid-gutter-width})));
				max-width: calc((83.33333% - (#{$grid-gutter-width})));
				margin-left: calc((16.66667% + (#{$grid-gutter-width} / 2)));
				height: $section-gallery-height-xxl;

				.slider {
					.slick-list {
						.slider__slide {
							height: $section-gallery-height-xxl;

							img {
								height: $section-gallery-height-xxl;
							}
						}
					}

					.slick-prev {
						left: calc((#{$slider-arrow-widht} / 2) * -1);
					}

					.slick-next {
						right: calc((#{$slider-arrow-widht} / 2) * -1);
					}
				}
			}
		}
	}
}
