// Components
//
// Define common padding and border radius sizes and more.

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius-sm:            1px;
$border-radius:               4px;
$border-radius-lg:            12px;
