.footer {
	display: inline-block;
	width: 100%;
	background-color: $primary;

	.footer__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		@extend .container-fluid;

		.footer__navigation {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			.footer__logo {
				display: flex;
				justify-content: center;
				width: 100%;
				padding: 0 $container-outer-padding;
				margin: ($spacer * 6) 0 ($spacer * 1) 0;

				svg {
					max-width: 232px;
				}
			}

			.footer__links {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 $container-outer-padding;
				margin-bottom: ($spacer * 3);
				list-style: none;

				.footer__link {
					display: inline-block;
					font-family: $font-family-monospace;
					font-size: $font-size--75;
					letter-spacing: 0.25px;

					a {
						color: $white;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}

				.footer__divider {
					display: inline-block;
					width: 2px;
					background: $white;
					height: 8px;
					opacity: 0.8;
					margin: 0 ($spacer * 1);
				}
			}

			.footer__social {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: normal;
				width: 100%;
				margin-bottom: ($spacer * 3);

				.btn {
					padding: 0 ($spacer * 2.5) 0 ($spacer * 2);
					background: $white;
					height: 44px;
					margin-right: ($spacer * 2);

					.button-icon {
						background: $white;
						box-shadow: $box-shadow--100;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						right: -($spacer * 2);
						top: -($spacer * 1);

						svg {
							fill: $primary;
							width: ($spacer * 2);
							height: ($spacer * 2);
						}
					}

					span {
						font-size: $font-size--75;
					}

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.footer__copy {
		width: 100%;
		color: $white;
		font-size: $font-size--50;
		font-family: $font-family-monospace;
		font-size: $font-size--50;
		letter-spacing: 0.25px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #282828;
		opacity: 0.2;
		padding: ($spacer * 1.5) 0;
	}
}
@include media-breakpoint-up(sm) {
	.footer {
		margin-top: $offset-top-sm;

		.footer__wrapper {
			.footer__navigation {
				.footer__logo {
					margin: ($spacer * 6) 0 ($spacer * 1.5) 0;

					svg {
						max-width: 260px;
					}
				}

				.footer__links {
					.footer__link {
						font-size: $font-size--100;
					}

					.footer__divider {
						height: ($spacer);
						margin: 0 ($spacer * 1.5);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.footer {
		margin-top: $offset-top-md;

		.footer__wrapper {
			padding-left: 0!important;
			padding-right: 0!important;

			.footer__navigation {
				margin-bottom: $spacer * 2;

				.footer__links {
					margin-bottom: $spacer * 4;
				}

				.footer__logo {
					margin: ($spacer * 8) 0 ($spacer * 1.5) 0;

					svg {
						max-width: 270px;
					}
				}

				.footer__social {
					margin-bottom: $spacer * 4;
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.footer {
		margin-top: $offset-top-lg;

		.footer__wrapper {
			padding-left: $container-outer-padding-lg !important;
			padding-right: $container-outer-padding-lg!important;

			.footer__navigation {
				padding: ($spacer * 8) 0 ($spacer * 7) 0;
				justify-content: center;
				align-items: flex-end;
				position: relative;
				margin: 0;

				.footer__logo {
					order: 2;
					margin: 0;
					width: auto;
					padding: 0;
				}

				.footer__links {
					position: absolute;
					right: 0;
					order: 3;
					margin: 0;
					padding: 0;
					width: auto;
					height: $spacer * 3;

					.footer__link {
						font-size: $font-size--75;
					}
				}

				.footer__social {
					position: absolute;
					left: 0;
					order: 1;
					background: transparent;
					opacity: 1;
					padding: 0;
					height: $spacer * 3;
					width: auto;
					margin-bottom: 0;

					.btn {
						height: 42px;
						padding-left: ($spacer * 1.5);
						padding-right: ($spacer * 2.5);
						.button-icon{
							width: 32px;
							height: 32px;
							right: -10px;
							top: -8px;

							svg{
								width: 18px;
								height: 18px;
							}
						}
						span {
							font-size: $font-size--75;
							margin-top: 1px;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.footer {
		margin-top: $offset-top-xl;

		.footer__wrapper {
			padding-left: $container-outer-padding-xl !important;
			padding-right: $container-outer-padding-xl!important;

			.footer__navigation {
				padding: ($spacer * 9) 0 ($spacer * 8) 0;

				.footer__logo {
					svg {
						max-width: 280px;
					}
				}

				.footer__links {
					.footer__link {
						font-size: $font-size--75;
					}
				}
			}
		}
	}
}
