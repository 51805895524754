$dialog-article-image-width: 70px;
$dialog-article-image-width-md: 80px;
$dialog-article-image-width-lg: 110px;
$dialog-divider-height: $spacer * 8;
$dialog-divider-space: $spacer * 3;

.dialog {
	width: 100%;

	.dialog__wrapper {
		width: 100%;
		position: relative;

		.dialog__body {
			z-index: 1;
			position: relative;
			margin-left: calc(#{$dialog-article-image-width} - (#{$spacer} * 1.5));

			.dialog__time {
				margin-bottom: ($spacer);
				font-family: $font-family-monospace;
				font-size: $font-size--75;
				letter-spacing: 0.25px;
				margin-left: ($spacer * 3);
			}

			.dialog__article {
				background: $white;
				padding: ($spacer * 2) ($spacer * 2) ($spacer * 2) ($spacer * 2);
				box-shadow: $box-shadow--100;
				display: inline-block;

				img {
					max-width: 100%;
					height: auto;
				}

				a {
					text-decoration: none;

					.dialog__article-title {
						font-size: $font-size--100;
						font-family: $font-family-base;
						font-weight: bold;
						margin-bottom: $spacer;
						line-height: 1.4;
					}

					&:hover {
						text-decoration: underline;
					}
				}

				.dialog__article-excerpt {
					overflow: hidden;
					
					p {
						font-size: $font-size--100;
						margin-bottom: 0;
						line-height: $line-height-initial;
					}
				}

				&:before {
					right: 100%;
					top: 80px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-right-color: #fff;
					border-width: $spacer;
					margin-top: - $spacer * 2;
				}

				&:after {
					content: '';
					position: absolute;
					left: calc(((#{$dialog-article-image-width} / 2) - (#{$spacer} * 1.5)) * -1);
					bottom: -$spacer;
					width: 2px;
					height: calc(100% - (#{$dialog-article-image-width} + #{$spacer}));
					background: $white;
				}
			}
		}

		.dialog__author {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;

			.dialog__author-image {
				width: $dialog-article-image-width;
				height: $dialog-article-image-width;
			}
		}
	}

	&.dialog--timeline {
		width: auto;
		width: 100%;
		max-width: 100%;

		.dialog__wrapper {
			margin-bottom: 0;
			position: relative;
			padding-bottom: $spacer * 3;

			.dialog__body {
				display: flex;
				flex-direction: column;
				z-index: 1;
				position: relative;
				margin-left: calc(#{$dialog-article-image-width} - (#{$spacer} * 1.5));
				padding-left: $spacer * 2;

				.dialog__article {
					align-self: flex-start;

					&:after {
						display: none;
					}

					&:before {
						right: calc(100% - 20px);
					}
				}
			}

			.dialog__author {
				padding-left: $spacer * 2;
			}

			&:before {
				top: calc((#{$dialog-article-image-width} / 2) - 4px);
				content: '';
				position: absolute;
				left: -8px;
				width: 16px;
				height: 16px;
				border: 4px solid $secondary;
				z-index: 2;
				background: $white;
				border-radius: 50px;
			}

			&:after {
				background: $white;
				width: 2px;
				left: -1px;
				top: 0;
				content: '';
				height: 100%;
				position: absolute;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.dialog {
		.dialog__wrapper {
			.dialog__body {
				margin-left: calc(#{$dialog-article-image-width-md} - (#{$spacer} * 1.5));

				.dialog__article {
					&:after {
						left: calc(((#{$dialog-article-image-width-md} / 2) - (#{$spacer} * 1.5)) * -1);
						height: calc(100% - (#{$dialog-article-image-width-md} + #{$spacer}));
					}

					&:before {
						right: 100%;
						top: 80px;
					}
				}
			}

			.dialog__author {
				.dialog__author-image {
					width: $dialog-article-image-width-md;
					height: $dialog-article-image-width-md;
				}
			}
		}

		&.dialog--timeline {
			width: 50%;
			max-width: 50%;

			.dialog__wrapper {
				padding: 0;

				.dialog__body {
					z-index: 1;
					position: relative;
					margin-left: calc(#{$dialog-article-image-width-md} - (#{$spacer} * 1.5));
				}

				&:before {
					top: calc((#{$dialog-article-image-width-md} / 2) - 4px);
				}
			}

			&.dialog--is-right-aligned {
				margin-right: 0;
				margin-right: 0;
				align-self: flex-start;

				.dialog__wrapper {
					.dialog__body {
						margin-left: 0;
						margin-right: calc(#{$dialog-article-image-width-md} - (#{$spacer} * 1.5));
						padding-left: 0;
						padding-right: $spacer * 2;

						.dialog__time {
							margin-right: 30px;
							text-align: right;
						}

						.dialog__article {
							align-self: flex-end;

							&:before {
								right: inherit;
								left: calc(100% - 20px);
								border-left-color: $white;
								border-right: none;
							}
						}
					}

					.dialog__author {
						right: 0;
						left: inherit;
						padding-left: 0;
						padding-right: $spacer * 2;
					}

					&:after {
						left: inherit;
						right: -1px;
					}

					&:before {
						left: inherit;
						right: -8px;
					}
				}
			}

			&.dialog--is-left-aligned {
				margin-left: 0;
				margin-right: 0;
				align-self: flex-end;

				.dialog__wrapper {
					&:before {
						right: inherit;
						left: -8px;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.dialog {
		.dialog__wrapper {
			.dialog__body {
				margin-left: calc(#{$dialog-article-image-width-lg} - (#{$spacer} * 1.5));

				.dialog__time {
					margin-bottom: ($spacer * 0.5);
				}

				.dialog__article {
					padding: ($spacer * 3) ($spacer * 3) ($spacer * 3) ($spacer * 3);

					&:after {
						left: calc(((#{$dialog-article-image-width-lg} / 2) - (#{$spacer} * 1.5)) * -1);
						height: calc(100% - (#{$dialog-article-image-width-lg} + #{$spacer}));
					}

					a {
						.dialog__article-title {
							font-size: $font-size--175;
						}
					}

					.dialog__article-excerpt {
						p {
							font-size: $font-size--175;
						}
					}
				}
			}

			.dialog__author {
				.dialog__author-image {
					width: $dialog-article-image-width-lg;
					height: $dialog-article-image-width-lg;
				}
			}
		}

		&.dialog--timeline {
			.dialog__wrapper {
				.dialog__body {
					z-index: 1;
					position: relative;
					margin-left: calc(#{$dialog-article-image-width-lg} - (#{$spacer} * 1.5));
				}

				&:before {
					top: calc((#{$dialog-article-image-width-lg} / 2) - 4px);
				}
			}

			&.dialog--is-right-aligned {
				.dialog__wrapper {
					.dialog__body {
						margin-right: calc(#{$dialog-article-image-width-lg} - (#{$spacer} * 1.5));

						.dialog__article {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}
