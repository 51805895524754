$person-offset: ($spacer);
$person-offset-sm: ($spacer * 0.5);
$person-offset-md: ($spacer * 0.5);
$person-offset-lg: ($spacer * 1);
$person-offset-xl: ($spacer * 3);

.person {
	position: relative;
	width: 100%;
	height: auto;
	background: $white;
	box-shadow: $box-shadow--50;
	transition: all 0.8s;

	.person__detail {
		position: absolute;
		bottom: $person-offset;
		z-index: 2;
		max-width: 90%;

		.person__name,
		.person__title {
			font-family: $font-family-monospace;
			font-size: $font-size--75;
			letter-spacing: 0.25px;
			text-align: left;
			color: $primary;
			background-color: $white;
			padding: ($spacer * 0.5) ($spacer);
			box-decoration-break: clone;
			-webkit-box-decoration-break: clone;
			box-shadow: $box-shadow--100;
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}

		.person__name {
			font-size: 22px;
			line-height: 1.82;
		}

		.person__title {
			font-size: $font-size--50;
			line-height: 2.35;
		}
	}

	.person__image {
		transition: all 0.8s;
		opacity: 0.5;
		width: 100%;
	}
}

@include media-breakpoint-up(sm) {
	.person {
		.person__detail {
			bottom: $person-offset-sm;

			.person__name {
				font-size: 22px;
				line-height: 1.82;
			}
			.person__title {
				font-size: $font-size--50;
				line-height: 2.4;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.person {
		.person__detail {
			bottom: $person-offset-md;

			.person__name{
				font-size: 28px;
				line-height: 1.7;
			}
			.person__title {
				font-size: $font-size--50;
				line-height: 2.4;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.person {
		.person__detail {
			bottom: $person-offset-lg;
			max-width: 70%;

			.person__name{
				font-size: 32px;
		    line-height: 1.65;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.person {
		.person__detail {
			bottom: $person-offset-xl;
			.person__name{
				font-size: 32px;
				line-height: 54px;
			}
		}
	}
}

@include media-breakpoint-up(xxl) {
	.person {
		.person__detail {
			bottom: $person-offset-xl;
			.person__name{
				font-size: 36px;
				line-height: 1.65;
			}
		}
	}
}
