.modal{
  z-index: 99999!important;
  .modal-dialog{
    margin: 20vh auto;
  }
  .modal-content{
    border-radius: 0;
    border:none;

    .modal-header{
      border: none;

    }

    .modal-body{
      border: none;
    }
  }
}
