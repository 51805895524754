$teaser-height: 90vw;
$teaser-height-sm: 40vw;
$teaser-height-md: 40vw;
$teaser-height-lg: 40vw;
$teaser-height-xl: 650px;

.teaser {
	display: flex;
	margin-bottom: $container-outer-padding;
	width: 100%;

	.teaser__wrapper {
		background-size: cover;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: $white;
		width: 100%;
		display: flex;
		box-shadow: $box-shadow--100;
		min-height: 320px;
		height: $teaser-height;
		padding: 25px;
		position: relative;
		overflow: hidden;

		.teaser__header {
			align-self: flex-end;
			z-index: 2;
			width: 100%;

			.teaser__subline {
				font-family: $font-family-monospace;
				font-size: 16px;
				letter-spacing: 0.25px;
				display: inline-flex;
				background: $white;
				padding: ($spacer * 0.5) $spacer;
				margin-bottom: 1px;
			}

			.teaser__title {
				position: relative;
				line-height: 1;
				margin-bottom: 0;

				a {
					text-decoration: none;

					span {
						text-align: left;
						color: $black;
						background-color: $white;
						box-decoration-break: clone;
						padding: ($spacer * 0.3) ($spacer);
						font-size: $font-post-fullscreen-xs;
						line-height: $line-height-post-fullscreen-xs;
						-webkit-box-decoration-break: clone;
						box-shadow: $box-shadow--100;
					}
				}
			}
		}

		.teaser__image {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			img,
			picture {
				min-width: 100%;
				max-height: 100%;
				width: auto;
				height: 100%;
				flex: 0 0 auto;
				object-fit: cover;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.teaser {
		padding: 0 ($spacer * 1.5);
		margin-bottom: $spacer * 3;

		.teaser__wrapper {
			min-height: $teaser-height-sm;
			height: $teaser-height-sm;

			.teaser__header {
				.teaser__subline {
					padding: ($spacer * 0.5) ($spacer * 1);
				}

				.teaser__title {
					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 1);
							font-size: $font-post-fullscreen-sm;
							line-height: $line-height-post-fullscreen-sm;
						}
					}
				}
			}
		}

		&.teaser--w-33 {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.teaser--w-66 {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.teaser--w-50 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}
@include media-breakpoint-up(md) {
	.teaser {
		padding: 0 ($spacer * 1.5);
		margin-bottom: ($spacer * 3);

		.teaser__wrapper {
			min-height: $teaser-height-md;
			height: $teaser-height-md;

			.teaser__header {
				.teaser__subline {
					padding: ($spacer * 0.5) ($spacer * 1.5);
				}

				.teaser__title {
					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 1.5);
							font-size: 36px;
							line-height: 1.65;
						}
					}
				}
			}
		}

		&.teaser--w-33 {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.teaser--w-66 {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.teaser--w-50 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}
@include media-breakpoint-up(lg) {
	.teaser {
		margin-bottom: ($spacer * 3);

		.teaser__wrapper {
			min-height: $teaser-height-lg;
			height: $teaser-height-lg;

			.teaser__header {
				.teaser__subline {
					font-size: 20px;
					padding: ($spacer * 0.8) ($spacer * 2);
					margin-bottom: 2px;
				}

				.teaser__title {
					a {
						span {
							padding: ($spacer * 0.5) ($spacer * 2);
							font-size: 40px;
							line-height: 1.6;
						}
					}
				}
			}
		}

		&.teaser--w-33 {
			flex: 0 0 50%;
			max-width: 50%;
		}

		&.teaser--w-66 {
			flex: 0 0 50%;
			max-width: 50%;

			.teaser__title {
				//max-width: 50%;
			}
		}

		&.teaser--w-50 {
			flex: 0 0 50%;
			max-width: 50%;

			.teaser__title {
				//max-width: 60%;
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.teaser {
		margin-bottom: ($spacer * 3);

		.teaser__wrapper {
			min-height: $teaser-height-xl;
			height: $teaser-height-xl;

			.teaser__header {
				.teaser__title {
					a {
						span {
							font-size: 46px;
							line-height: 1.5;
						}
					}
				}
			}
		}

		&.teaser--w-33 {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}

		&.teaser--w-66 {
			flex: 0 0 66.66667%;
			max-width: 66.66667%;

			.teaser__title {
				//max-width: 50%;
			}
		}

		&.teaser--w-50 {
			flex: 0 0 50%;
			max-width: 50%;

			.teaser__title {
				//max-width: 60%;
			}
		}
	}
}
