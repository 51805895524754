.header-page {
	margin-top: $header-height-xs;
	width: 100%;
	height: auto;
	@extend .container-fluid;

	.header-page__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.header-page__container {
			flex: 0 0 100%;
			max-width: 100%;

			.header-page__container-item {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				z-index: 2;
				padding-top: ($spacer * 5);
				padding-bottom: ($spacer * 5);

				.header-page__header {
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;
					width: 80%;

					.header-page__title {
						line-height: 1;
						margin: 0;

						span {
							text-align: left;
							color: $black;
							background-color: $white;
							padding: ($spacer * 0.3) ($spacer);
							box-decoration-break: clone;
							-webkit-box-decoration-break: clone;
							box-shadow: $box-shadow--100;
							font-size: $font-post-fullscreen-xs;
							line-height: $line-height-post-fullscreen-xs;
						}
					}
				}

				.header-page__body {
					width: 100%;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;
					width: 80%;
					margin-top: ($spacer * 2);

					.header-page__body-item {}
				}
			}

			.header-page__container-back {
				width: 100%;
				background: $secondary;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.header-page {
		margin-top: $header-height-sm;

		.header-page__wrapper {
			.header-page__container {
				.header-page__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);

					.header-page__header {
						flex: 0 0 calc((50% - #{$grid-gutter-width}));
						max-width: calc((50% - #{$grid-gutter-width}));
						padding-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
						padding-right: ($grid-gutter-width / 2);

						.header-page__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 1);
								font-size: $font-post-fullscreen-sm;
								line-height: $line-height-post-fullscreen-sm;
							}
						}
					}

					.header-page__body {
						flex: 0 0 100%;
						max-width: 100%;
						margin-top: ($spacer * 2);
						padding-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
						padding-right: ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.header-page {
		margin-top: $header-height-md;

		.header-page__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.header-page__container {
				.header-page__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);

					.header-page__header {
						flex: 0 0 calc((50% - #{$grid-gutter-width}));
						max-width: calc((50% - #{$grid-gutter-width}));
						padding: 0 ($grid-gutter-width / 2);

						.header-page__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 2);
								font-size: $font-post-fullscreen-md;
								line-height: $line-height-post-fullscreen-md;
							}
						}
					}

					.header-page__body {
						flex: 0 0 100%;
						max-width: 100%;
						padding: 0 ($grid-gutter-width / 2);
						transform: translateX(0);
						margin-top: ($spacer * 2);
					}
				}

				.header-page__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
					img{
						object-fit: cover;
						width: 100%;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.header-page {
		margin-top: $header-height-lg;

		.header-page__wrapper {
			.header-page__container {
				.header-page__container-item {
					padding: ($spacer * 8) 0;

					.header-page__header {
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 8.33333%;

						.header-page__title {
							span {
								padding: ($spacer * 0.5) ($spacer * 2);
								font-size: $font-post-fullscreen-lg;
								line-height: $line-height-post-fullscreen-lg;
							}
						}
					}

					.header-page__body {
						flex: 0 0 calc((91.66667% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
						max-width: calc((91.66667% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
						margin-left: 8.33333%;
						margin-top: ($spacer * 3);
					}
				}

				.header-page__container-back {
					flex: 0 0 calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					max-width: calc((83.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					margin-left: calc((16.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.header-page {
		margin-top: $header-height-xl;

		.header-page__wrapper {
			.header-page__container {
				.header-page__container-item {
					padding: ($spacer * 10) 0;
				}

				.header-page__container-back {
					flex: 0 0 calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
					max-width: calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
					margin-left: calc(16.66667% + (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.header-page {
		.header-page__wrapper {
			.header-page__container {
				.header-page__container-item {
					.header-page__header {
						.header-page__title {
							span {
								font-size: $font-post-fullscreen-xxl;
								line-height: $line-height-post-fullscreen-xxl;
							}
						}
					}
				}

				.header-page__container-back {
					flex: 0 0 calc((83.33333% - (#{$grid-gutter-width})));
					max-width: calc((83.33333% - (#{$grid-gutter-width})));
					margin-left: calc((16.66667% + #{$grid-gutter-width}) - (#{$grid-gutter-width} / 2));
				}
			}
		}
	}
}
