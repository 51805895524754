.filter {
	.filter__wrapper {
		display: flex;
		flex-wrap: wrap;

		.filter__facet {
			background: $white;
			color: $primary;
			font-size: $font-size--75;
			font-family: $font-family-monospace;
			letter-spacing: 0.25px;
			display: inline-flex;
			height: 42px;
			padding: 0 ($spacer * 1.5);
			align-items: center;
			box-shadow: $box-shadow--100;
			text-decoration: none;
			margin-right: ($spacer * 0.5);
			margin-bottom: ($spacer * 0.5);

			&:hover {
				text-decoration: underline;
			}

			&.active {
				background: $primary;
				color: $white;
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.filter {
		.filter__wrapper {
			.filter__facet {
				padding: 0 ($spacer * 2);
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.filter {
		.filter__wrapper {
			.filter__facet {
				font-size: $font-size--175;
				height: 54px;
				padding: 0 ($spacer * 2);
			}
		}
	}
}
