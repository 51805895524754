$stage-author-widht: 70px;
$stage-author-widht-sm: 80px;
$stage-author-widht-md: 140px;
$stage-author-widht-lg: 170px;
$stage-author-widht-xl: 216px;
$stage-height: 200px;
$stage-height-sm: 260px;
$stage-height-md: 400px;
$stage-height-lg: 480px;
$stage-height-xl: 640px;
$stage-height-xxl: 720px;

.stage {
	width: 100%;
	height: auto;
	margin-top: $header-height-xs;
	@extend .container-fluid;

	.stage__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.stage__header {
			order: 2;
			z-index: 2;
			padding-left: $container-outer-padding;
			padding-right: $container-outer-padding;
			width: 100%;
			margin-top: -22px;
			margin-bottom: $article-bottom-margin;
			.stage__subline{
				font-family: $font-family-monospace;
				font-size: $font-size--75;
				letter-spacing: 0.25px;
				display: inline-flex;
				background: $white;
				padding: ($spacer * 0.5) $spacer;
				margin-bottom: 1px;
					box-shadow: $box-shadow--100;
			}
			.stage__title {
				line-height: 1;
				margin-bottom: ($spacer * 1);

				span {
					text-align: left;
					color: $black;
					background-color: $white;
					padding: ($spacer * 0.3) ($spacer);
					box-decoration-break: clone;
					-webkit-box-decoration-break: clone;
					box-shadow: $box-shadow--100;
					font-size: $font-post-fullscreen-xs;
					line-height: $line-height-post-fullscreen-xs;
				}
			}

			.stage__author {
				font-family: $font-family-monospace;
				font-size: $font-size--75;
				letter-spacing: 0.25px;
				display: inline-flex;

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.stage__body {
			position: relative;
			width: 100%;
			min-height: $stage-height;
			max-height: $stage-height;
			order: 1;

			.stage__image {
				width: 100%;
				height: 100%;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		.stage__footer {
			position: absolute;
			top: 70px;
			left: calc(#{$container-outer-padding} - 5px);
			width: $stage-author-widht;
			height: $stage-author-widht;
			z-index: 2;

			.stage__author-image {
				width: 100%;
				object-fit: cover;
				box-shadow: $box-shadow--100;
				display: none;
			}
		}
	}

	&.stage--minimal {
		margin-bottom: $article-bottom-margin;

		.stage__wrapper {
			.stage__header {
				margin-bottom: 0;

				.stage__title {
					margin-bottom: 0;
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.stage {
		margin-top: $header-height-sm;

		.stage__wrapper {
			.stage__header {
				order: 2;
				flex: 0 0 calc((83.33333% - #{$grid-gutter-width}));
				max-width: calc((83.33333% - #{$grid-gutter-width}));
				padding-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
				padding-right: ($grid-gutter-width / 2);
				margin-bottom: $article-bottom-margin-sm;
				margin-top: -25px;

				.stage__subline{
					padding: ($spacer * 0.5) ($spacer * 1);
				}

				.stage__title {
					span {
						padding: ($spacer * 0.5) ($spacer * 1);
						font-size: $font-post-fullscreen-sm;
						line-height: $line-height-post-fullscreen-sm;
					}
				}
			}

			.stage__body {
				order: 1;
				flex: 0 0 100%;
				max-width: 100%;
				min-height: $stage-height-sm;
				max-height: $stage-height-sm;
			}

			.stage__footer {
				position: absolute;
				bottom: calc((#{$stage-author-widht-sm} / 2) * -1);
				width: $stage-author-widht-sm;
				height: $stage-author-widht-sm;
				top: 130px;
				left: calc((8.33333% + (#{$grid-gutter-width} / 2)) - 15px);
				z-index: 1;
			}
		}
	}

	&.stage--minimal {
		margin-bottom: $article-bottom-margin-sm;

		.stage__wrapper {
			.stage__header {
				margin-bottom: 0;

				.stage__title {
					margin-bottom: 0;
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.stage {
		margin-top: $header-height-md;
		margin-bottom: calc(#{$article-bottom-margin-md} + (#{$stage-author-widht-md} / 2));

		.stage__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.stage__header {
				flex: 0 0 calc((50% - #{$grid-gutter-width}));
				max-width: calc((50% - #{$grid-gutter-width}));
				margin-left: $grid-gutter-width / 2;
				padding-right: 0;
				padding-left: 0;
				bottom: 0;
				order: 1;
				position: absolute;
				margin-bottom: 0;
				.stage__subline{
					padding: ($spacer * 0.5) ($spacer * 1.5);
				}
				.stage__title {
					margin-bottom: 1px;

					span {
						padding: ($spacer * 0.5) ($spacer * 2);
						font-size: $font-post-fullscreen-md;
						line-height: $line-height-post-fullscreen-md;
					}
				}

				.stage__author {
					margin-bottom: ($spacer * 3);
					background: $white;
					padding: ($spacer * 0.5) $spacer;
					box-shadow: $box-shadow--50;
				}
			}

			.stage__body {
				flex: 0 0 calc((91.66667% - #{$grid-gutter-width}) + #{$container-outer-padding-md});
				max-width: calc((91.66667% - #{$grid-gutter-width}) + #{$container-outer-padding-md});
				margin-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
				min-height: $stage-height-md;
				max-height: $stage-height-md;
				order: 2;
			}

			.stage__footer {
				position: absolute;
				bottom: calc((#{$stage-author-widht-md} / 2) * -1);
				left: calc(50% - #{$stage-author-widht-md} / 2);
				width: $stage-author-widht-md;
				height: $stage-author-widht-md;
				top: inherit;
				right: inherit;

				.stage__author-image {
					display: block;
				}
			}
		}

		&.stage--minimal {
			margin-bottom: $article-bottom-margin-md;

			.stage__wrapper {
				.stage__header {
					margin-bottom: $spacer * 3;
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.stage {
		margin-top: $header-height-lg;
		margin-bottom: calc(#{$article-bottom-margin-lg} + (#{$stage-author-widht-lg} / 2));

		.stage__wrapper {
			.stage__header {
				flex: 0 0 calc((50% - #{$grid-gutter-width}));
				max-width: calc((50% - #{$grid-gutter-width}));
				margin-left: calc(8.33333% + (#{$grid-gutter-width} / 2));
				.stage__subline{
					font-size: 20px;
					padding: ($spacer * 0.8) ($spacer * 2);
					margin-bottom: 2px;
				}
				.stage__title {
					margin-bottom: ($spacer * 0.1);

					span {
						padding: ($spacer * 0.5) ($spacer * 2);
						font-size: $font-post-fullscreen-lg;
						line-height: $line-height-post-fullscreen-lg;
					}
				}
			}

			.stage__body {
				flex: 0 0 calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
				max-width: calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-lg});
				margin-left: calc(16.66667% + (#{$grid-gutter-width} / 2));
				min-height: $stage-height-lg;
				max-height: $stage-height-lg;
			}

			.stage__footer {
				position: absolute;
				bottom: calc((#{$stage-author-widht-lg} / 2) * -1);
				left: calc(50% - #{$stage-author-widht-lg} / 2);
				width: $stage-author-widht-lg;
				height: $stage-author-widht-lg;
			}
		}

		&.stage--minimal {
			margin-bottom: $article-bottom-margin-lg;
		}
	}
}
@include media-breakpoint-up(xl) {
	.stage {
		margin-top: $header-height-xl;
		margin-bottom: calc(#{$article-bottom-margin-lg} + (#{$stage-author-widht-xl} / 2));

		.stage__wrapper {
			.stage__body {
				flex: 0 0 calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
				max-width: calc((83.33333% - #{$grid-gutter-width}) + #{$container-outer-padding-xl});
				margin-left: calc(16.66667% + (#{$grid-gutter-width} / 2));
				min-height: $stage-height-xl;
				max-height: $stage-height-xl;
			}

			.stage__footer {
				bottom: calc((#{$stage-author-widht-xl} / 2) * -1);
				left: calc(50% - #{$stage-author-widht-xl} / 2);
				width: $stage-author-widht-xl;
				height: $stage-author-widht-xl;
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.stage {
		margin-top: $header-height-xl;

		.stage__wrapper {
			.stage__header {
				flex: 0 0 calc((50% - #{$grid-gutter-width}));
				max-width: calc((50% - #{$grid-gutter-width}));
				margin-left: calc(8.33333% + (#{$grid-gutter-width} / 2));

				.stage__title {
					span {
						font-size: $font-post-fullscreen-xxl;
						line-height: $line-height-post-fullscreen-xxl;
					}
				}
			}

			.stage__body {
				flex: 0 0 calc((83.33333% - (#{$grid-gutter-width})));
				max-width: calc((83.33333% - (#{$grid-gutter-width})));
				margin-left: calc((16.66667% + #{$grid-gutter-width}) - (#{$grid-gutter-width} / 2));
				transform: translateX(0);
				min-height: $stage-height-xxl;
				max-height: $stage-height-xxl;
			}
		}
	}
}
