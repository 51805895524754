$widget-slider-padding: 15px;
$widget-slider-padding-sm: 15px;
$widget-slider-padding-md: 15px;
$widget-slider-padding-lg: ($grid-gutter-width / 2);
$widget-person-offset: $grid-gutter-width;

.widget-person {
	width: 100%;
	height: auto;
	z-index: 0;
	position: relative;
	@extend .container-fluid;

	.widget-person__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.widget-person__container {
			padding-top: ($spacer * 5);
			padding-bottom: ($spacer * 5);
			width: 100%;

			.widget-person__header {
				width: 100%;
				position: relative;
				z-index: 2;
				padding-left: $container-outer-padding;
				padding-right: $container-outer-padding;
				margin-bottom: ($spacer * 2);

				.widget-person__title {
					line-height: 1;
					margin-bottom: 0;

					span {
						text-align: left;
						color: $black;
						background-color: $white;
						padding: ($spacer * 0.3) ($spacer);
						box-decoration-break: clone;
						font-size: $font-post-fullscreen-xs;
						line-height: $line-height-post-fullscreen-xs;
						-webkit-box-decoration-break: clone;
					}
				}
			}

			.widget-person__body {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 2;
				flex-wrap: wrap;
				padding-left: calc(#{$container-outer-padding});
				padding-right: calc(#{$container-outer-padding});

				.widget-person__body-wrapper {
					flex: 0 0 calc(100%);
					max-width: calc(100%);

					.widget-person__body-item {
						.slider {
							.slick-list {
								padding: 0!important !important;

								.slider__slide {
									padding: $widget-slider-padding;
									transition: all 0.8s;

									.person {
										.person__detail {
											transition: all 0.6s;
											opacity: 0;
										}
									}

									&.slick-current {
										transform: scale(1.1);

										.person {
											box-shadow: $box-shadow--25;

											.person__image {
												opacity: 1;
											}

											.person__detail {
												opacity: 1;
											}
										}
									}
								}
							}

							.slick-prev {
								left: calc((60px / 3) * -1);
								padding: 0;
								width: 60px;
							}

							.slick-next {
								right: calc((60px / 3) * -1);
								padding: 0;
								width: 60px;
							}
						}
					}
				}
			}

			.widget-person__container-back {
				background: $secondary;
				width: 100%;
				position: absolute;
				height: 100%;
				top: 0;
				z-index: 1;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.widget-person {
		transform: translateY($offset-top-sm);

		.widget-person__wrapper {
			.widget-person__container {
				padding-top: ($spacer * 6);
				padding-bottom: ($spacer * 6);

				.widget-person__header {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);

					.widget-person__title {
						span {
							padding: ($spacer * 0.5) ($spacer * 1);
							font-size: $font-post-fullscreen-sm;
							line-height: $line-height-post-fullscreen-sm;
						}
					}
				}

				.widget-person__body {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);
					justify-content: inherit;

					.widget-person__body-wrapper {
						width: calc((100% + (#{$widget-slider-padding-sm})));
						max-width: calc((100% + (#{$widget-slider-padding-sm})));

						.widget-person__body-item {
							.slider {
								.slick-list {
									.slider__slide {
										padding: $widget-slider-padding-sm;

										&.slick-current {
											transform: scale(1.15);
										}
									}
								}

								.slick-prev {
									left: calc(((#{$slider-arrow-widht} / 2) * -1));
									padding: ($spacer);
									width: 80px;
								}

								.slick-next {
									right: calc(((#{$slider-arrow-widht} / 2) - (#{$grid-gutter-width} / 4)) * -1);
									padding: ($spacer);
									width: 80px;
								}
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.widget-person {
		transform: translateY($offset-top-md);

		.widget-person__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.widget-person__container {
				padding-top: ($spacer * 6);
				padding-bottom: ($spacer * 6);

				.widget-person__header {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
					padding: 0 ($grid-gutter-width / 2);
					margin-bottom: ($spacer * 1);
					margin-left: 16.66667%;

					.widget-person__title {

						span {
							padding: ($spacer * 0.5) ($spacer * 2);
							font-size: $font-post-fullscreen-md;
							line-height: $line-height-post-fullscreen-md;
						}
					}
				}

				.widget-person__body {
					flex: 0 0 calc((91.66667% + #{$grid-gutter-width}) + (#{$widget-slider-padding-md} * 2));
					max-width: calc((91.66667% + #{$grid-gutter-width}) + (#{$widget-slider-padding-md} * 2));
					padding: 0 ($grid-gutter-width / 2);
					margin: 0;

					.widget-person__body-wrapper {
						width: calc((100% + (#{$widget-slider-padding-md} * 2)));
						max-width: calc((100% + (#{$widget-slider-padding-md} * 2)));
						transform: translateX(calc((#{$widget-slider-padding-md}) * -1));

						.widget-person__body-item {
							flex: 0 0 calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-md} * 2));
							max-width: calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-md} * 2));

							.slider {
								.slick-list {
									.slider__slide {
										padding: $widget-slider-padding-md;

										&.slick-current {
											transform: scale(1.1);
										}
									}
								}

								.slick-prev {
									left: calc(((#{$slider-arrow-widht} / 2) - (#{$grid-gutter-width} / 4)) * -1);
								}

								.slick-next {
									right: calc(((#{$slider-arrow-widht} / 2) - (#{$grid-gutter-width} / 4)) * -1);
								}
							}
						}
					}
				}

				.widget-person__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-md}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-md}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.widget-person {
		transform: translateY($offset-top-lg);

		.widget-person__wrapper {
			.widget-person__container {
				flex: 0 0 100%;
				max-width: 100%;
				padding: ($spacer * 10) 0;

				.widget-person__header {
					margin-left: 16.66667%;
					flex: 0 0 75%;
					max-width: 75%;
					padding: 0 ($grid-gutter-width / 2);
					margin-bottom: ($spacer);

					.widget-person__title {

						span {
							padding: ($spacer * 0.5) ($spacer * 2);
							font-size: 4vw;
							line-height: calc(4vw * 1.5);
						}
					}
				}

				.widget-person__body {
					flex: 0 0 91.66667%;
					max-width: 91.66667%;
					margin-left: 8.33333%;
					padding: 0 ($grid-gutter-width / 2);

					.widget-person__body-wrapper {
						width: calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-lg} * 2));
						max-width: calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-lg} * 2));
						transform: translateX(calc((#{$grid-gutter-width} + #{$widget-slider-padding-lg}) * -1));

						.widget-person__body-item {
							flex: 0 0 calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-lg} * 2));
							max-width: calc((100% + #{$grid-gutter-width}) + (#{$widget-slider-padding-lg} * 2));

							.slider {
								.slick-prev {
									left: calc(((#{$slider-arrow-widht} / 2) - (#{$grid-gutter-width} / 2)) * -1);
								}

								.slick-next {
									right: calc(((#{$slider-arrow-widht} / 2) - (#{$grid-gutter-width} / 2)) * -1);
								}

								.slick-list {
									.slider__slide {
										padding: $widget-slider-padding-lg;
									}
								}
							}
						}
					}
				}

				.widget-person__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-lg}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.widget-person {
		transform: translateY($offset-top-xl);

		.widget-person__wrapper {
			.widget-person__container {
				.widget-person__container-back {
					flex: 0 0 calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					max-width: calc((91.66667% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width}));
					margin-left: calc((8.33333% + #{$container-outer-padding-xl}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.widget-person {
		transform: translateY($offset-top-xl);

		.widget-person__wrapper {
			.widget-person__container {
				.widget-person__header {
					.widget-person__title {
						span {
							font-size: $font-post-fullscreen-xxl;
							line-height: $line-height-post-fullscreen-xxl;
						}
					}
				}

				.widget-person__container-back {
					flex: 0 0 calc((91.66667% - (#{$grid-gutter-width})));
					max-width: calc((91.66667% - (#{$grid-gutter-width})));
					margin-left: calc((8.33333% + #{$grid-gutter-width}) - (#{$grid-gutter-width} / 2));
					transform: translateX(0);
				}
			}
		}
	}
}


// Edge styles
@supports (-ms-ime-align: auto) {
	.widget-person {
		transform: translateY(0) !important;
		z-index: -1 !important;
	}
	@include media-breakpoint-up(sm) {
		.widget-person {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(md) {
		.widget-person {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(lg) {
		.widget-person {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(xl) {
		.widget-person {
			transform: translateY(0) !important;
		}
	}
	@include media-breakpoint-up(xxl) {
		.widget-person {
			transform: translateY(0) !important;
		}
	}
}
