.section-article {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-article__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-article__body {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.section-article__body-item {
				padding-left: $container-outer-padding;
				padding-right: $container-outer-padding;

				p {
					&:last-child {
						margin-bottom: 0;
					}
				}

				img {
					width: 100%;
					height: auto;
				}

				* {
					&:first-child {
						margin-top: 0;
					}
				}

				iframe {
					min-height: 200px;
					margin-bottom: $text-bottom-margin;
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-article {
		margin-bottom: $article-bottom-margin-sm;

		.section-article__wrapper {
			.section-article__body {
				.section-article__body-item {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);

					iframe {
						min-height: 280px;
						margin-bottom: $text-bottom-margin-sm;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-article {
		margin-bottom: $article-bottom-margin-md;

		.section-article__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-article__body {
				.section-article__body-item {
					iframe {
						min-height: 320px;
						margin-bottom: $text-bottom-margin-md;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-article {
		margin-bottom: $article-bottom-margin-lg;

		.section-article__wrapper {
			.section-article__body {
				.section-article__body-item {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
					margin-left: 16.66667%;

					iframe {
						min-height: 400px;
						margin-bottom: $text-bottom-margin-lg;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-article {
		.section-article__wrapper {
			.section-article__body {
				.section-article__body-item {
					iframe {
						min-height: 530px;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-article {
		.section-article__wrapper {
			.section-article__body {
				.section-article__body-item {
					iframe {
						min-height: 480px;
					}
				}
			}
		}
	}
}
