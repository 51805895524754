.header-event {
	margin-top: calc(#{$header-height-xs} + (#{$spacer} * 2));

	.grid-feed {
		.grid-feed__wrapper {
			.grid-feed__body {
				.post-event {
					@extend .post;
					.post__wrapper {
						.post__title {
							text-align: left;
							line-height: 1;
							margin-bottom: ($spacer * 1);

							a {
								span {
									text-align: left;
									color: $primary;
									background-color: $white;
									padding: ($spacer * 0.3) ($spacer);
									font-size: 26px;
									line-height: 1.5;
									box-decoration-break: clone;
									-webkit-box-decoration-break: clone;
								}

								&:hover {
									color: $primary!important;
								}
							}
						}

						.post-event__meta {
							display: flex;
							flex-flow: column wrap;
							margin: ($spacer * 1.5) auto 0 auto;

							.post-event__date,
							.post-event__location {
								font-size: $font-size--100;
								line-height: 1;
							}

							.post-event__location {
								margin-top: ($spacer * 0.5);
							}
						}

						.post__excerpt {
							margin: ($spacer * 1.5) auto 0 auto;

							p{
								margin: 0;
								font-size: 14px;
							}
						}
					}

					&:first-child {
						box-shadow: none;
						height: auto!important;
					}

					&:nth-child(2) {
						box-shadow: $box-shadow--100;
						z-index: 0;
						display: none;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.header-event {
		margin-top: calc(#{$header-height-sm} + (#{$spacer} * 4));

		.grid-feed {
			.grid-feed__wrapper {
				.grid-feed__body {
					.post-event {
						padding: 30px;
						.post__wrapper {
							.post__title {
								margin-bottom: ($spacer * 1);

								a {
									span {
										padding: ($spacer * 0.5) ($spacer * 1);
										font-size: 26px;
										line-height: 1.7;
									}
								}
							}
						}

						&:first-child {
							box-shadow: $box-shadow--100;
						}

						&:nth-child(2) {
							box-shadow: none;
							display: flex;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.header-event {
		margin-top: calc(#{$header-height-md} + (#{$spacer} * #{$header-offset-factor-parallax-new}));

		.grid-feed {
			.grid-feed__wrapper {
				.grid-feed__body {
					.post-event {
						padding: 30px;

						.post__wrapper {
							.post__title {
								margin-bottom: ($spacer * 1.5);

								a {
									span {
										padding: ($spacer * 0.5) ($spacer * 2);
										font-size: 34px;
										line-height: 1.6;
									}
								}
							}

							.post-event__meta {
								margin-top: ($spacer * 1.5);
							}

							.post__excerpt {
								margin-top: ($spacer * 1.5);
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.header-event {
		margin-top: calc(#{$header-height-lg} + (#{$spacer} * #{$header-offset-factor-parallax-new}));

		.grid-feed {
			.grid-feed__wrapper {
				.grid-feed__body {
					.post-event {
						padding: $post-inner-padding-lg;
						.post__wrapper {
							.post__title {
								a {
									span {
										font-size: $font-post-fullscreen-lg;
										line-height: $line-height-post-fullscreen-lg;
									}
								}
							}

							.post__excerpt {
								p{
									font-size: 20px;
								}
							}

							.post-event__meta {
								margin-top: ($spacer * 3);

								.post-event__date,
								.post-event__location {
									font-size: $font-size--175;
								}

								.post-event__location {
									margin-top: $spacer;
								}
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.header-event {
		margin-top: calc(#{$header-height-xl} + (#{$spacer} * #{$header-offset-factor-parallax-new}));
		.grid-feed {
			.grid-feed__wrapper {
				.grid-feed__body {
					.post-event {
						padding: $post-inner-padding-xl;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.header-event {
		.grid-feed {
			.grid-feed__wrapper {
				.grid-feed__body {
					.post-event {
						.post__wrapper {
							.post__title {
								a {
									span {
										font-size: $font-post-fullscreen-xxl;
										line-height: $line-height-post-fullscreen-xxl;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
