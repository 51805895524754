.header {
	.navbar {
		.container-fluid {
			.searchform-toggler {
				z-index: 999;

				svg {
					height: 18px;
				}
			}

			.searchform__close-button {
				z-index: 9999;
				display: none;
				width: 16px;
				height: 16px;
				margin-bottom: 4px;

				svg {
					width: 16px;
					height: 16px;
				}
			}

			.searchform-collapse {
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				display: flex;
				align-items: center !important;
				justify-content: flex-start !important;
				overflow-y: auto;
				transform: translateX(-100%);
				opacity: 0.1;
				z-index: 1030;
				align-items: center;
				justify-content: center;
				padding: 0;
				background: $white;
				height: $header-height-xs;

				.searchform-collapse__wrapper {
					display: flex;
					align-items: flex-end;

					@extend .container-fluid;

					.searchform__search {
						display: inline-flex;
						position: relative;
						width: 100%;
						align-items: center;
						margin-left:$container-outer-padding;
						margin-right: $container-outer-padding;

						.searchform__search-input {
							border: none;
							width: 100%;
							font-size: $font-size--100;
							transition: all 0.5s 0.3s ease-in-out;
							position: relative;
							z-index: 998;
							padding: 5px 0 0;
							padding-left: 25px;
							border-bottom: 3px solid $secondary;

							&:focus {
								outline: none;
							}
						}

						.searchform__search-button {
							position: absolute;
							right: 0;
							z-index: 999;
							font-family: $font-family-monospace;
							font-size: $font-size--100;
							letter-spacing: 0.25px;
							text-decoration: none;
							display: inline-flex;
							font-weight: normal;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}

				&.open {
					transform: translateX(0px);
					opacity: 1;

					.searchform__search-button {
						display: block;
					}
				}
			}
		}
	}

	&.shrinked {
		.navbar {
			.container-fluid {
				button {
					margin-bottom: 4px;
					svg {
						width: 14px;
						height: 14px;
					}
				}
				.searchform-collapse {
					height: $header-height-shrinked-xs;
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.header {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-sm;

					.searchform-collapse__wrapper {

						.searchform__search {
							margin-right: ($grid-gutter-width / 2);
							margin-left: ($grid-gutter-width / 2);
						}
					}
				}
			}
		}
	}

	.header.shrinked {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-shrinked-sm;
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.header {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-md;

					.searchform-collapse__wrapper {
						padding: 0 $container-outer-padding-md;

						.searchform__search {
							margin: 0;
							.searchform__search-input {
								height: 40px;
								padding-left: 25px;
								padding-top: 5px;
							}
						}
					}
				}
			}
		}
	}

	.header.shrinked {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-shrinked-md;
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.header {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-lg;
					align-items: flex-end!important;
					padding-bottom: $spacer * 3;

					.searchform-collapse__wrapper {
						padding: 0 $container-outer-padding-lg;

						.searchform__search {
							.searchform__search-input {
								height: 48px;
								padding-left: 25px;
								font-size: $font-size--175;
							}

							.searchform__search-button {
								font-size: $font-size--175;
							}
						}
					}
				}
			}
		}
	}

	.header.shrinked {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-shrinked-lg;
						padding-bottom: $spacer * 2;
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.header {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-xl;

					.searchform-collapse__wrapper {
						height: 60px;
						padding-right: $container-outer-padding-xl;
						padding-left: $container-outer-padding-xl;

						.searchform__search {
							.searchform__search-input {
								height: 50px;
								padding-left: 30px;
								padding-top: 10px;
							}
						}
					}
				}
			}
		}
	}

	.header.shrinked {
		.navbar {
			.container-fluid {
				.searchform-collapse {
					height: $header-height-shrinked-xl;
				}
			}
		}
	}
}
