$teaser-related-image-height: 80px;
$teaser-related-image-height-sm: 110px;
$teaser-related-image-height-md: 180px;
$teaser-related-image-height-lg: 180px;
$teaser-related-image-height-xl: 180px;
$teaser-related-image-height-xxl: 180px;

.teaser-related {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: $spacer * 2;

	.teaser-related__image {
		width: $teaser-related-image-height;
		height: $teaser-related-image-height;
		overflow: hidden;
		margin-right: ($spacer * 1.5);
		flex-shrink: 0;
		position: relative;

		.teaser-related__image-wrapper {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			img {
				min-width: 100%;
				max-height: 100%;
				width: auto;
				height: 100%;
				flex: 0 0 auto;
				object-fit: cover;
			}
		}
	}

	.teaser-related__wrapper {
		.teaser-related__title {
			margin-bottom: $spacer * 0.5;
			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.teaser-related__excerpt {
			margin-bottom: ($spacer * 0.5);
			display: none;

			p {
				margin-bottom: 0;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.teaser-related {
		margin-bottom: $spacer * 2.5;

		.teaser-related__image {
			width: $teaser-related-image-height-sm;
			height: $teaser-related-image-height-sm;
			margin-right: ($spacer * 2.5);
		}

		.teaser-related__wrapper {
			.teaser-related__excerpt {
				display: flex;
				margin-bottom: ($spacer * 1);
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.teaser-related {
		margin-bottom: $spacer * 2;

		.teaser-related__image {
			width: $teaser-related-image-height-md;
			height: $teaser-related-image-height-md;
			margin-right: ($spacer * 3);
		}

		.teaser-related__wrapper {
			.teaser-related__title {
				margin-bottom: $spacer * 1;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.teaser-related {
		margin-bottom: $spacer * 3;

		.teaser-related__image {
			width: $teaser-related-image-height-lg;
			height: $teaser-related-image-height-lg;
			margin-right: ($spacer * 3);
		}
	}
}
@include media-breakpoint-up(xl) {
	.teaser-related {
		.teaser-related__image {
			width: $teaser-related-image-height-xl;
			height: $teaser-related-image-height-xl;
			margin-right: ($spacer * 3);
		}
	}
}
@include media-breakpoint-up(xxl) {
	.teaser-related {
		.teaser-related__image {
			width: $teaser-related-image-height-xxl;
			height: $teaser-related-image-height-xxl;
		}
	}
}
