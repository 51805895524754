$header-height-xs: 80px;
$header-height-sm: 100px;
$header-height-md: 100px;
$header-height-lg: 120px;
$header-height-xl: 134px;
$header-height-shrinked-xs: 60px;
$header-height-shrinked-sm: 70px;
$header-height-shrinked-md: 80px;
$header-height-shrinked-lg: 100px;
$header-height-shrinked-xl: 110px;
$navbar-offcanvas-width: 220px;
$navbar-offcanvas-width-sm: 240px;
$navbar-offcanvas-width-md: 300px;
$navbar-offcanvas-width-lg: 330px;
$navbar-offcanvas-width-xl: 380px;
$navbar-offcanvas-width-xxl: 430px;
$logo-width-xs: 160px;
$logo-width-shrinked-xs: 140px;
$logo-width-sm: 180px;
$logo-width-shrinked-sm: 160px;
$logo-width-md: 200px;
$logo-width-shrinked-md: 180px;
$logo-width-lg: 220px;
$logo-width-shrinked-lg: 200px;
$logo-width-xl: 260px;
$logo-width-shrinked-xl: 240px;
$offset-top: -25px;
$offset-bottom: -25px;
$offset-top-sm: 20px;
$offset-bottom-sm: -20px;
$offset-top-md: 20px;
$offset-bottom-md: -20px;
$offset-top-lg: 40px;
$offset-bottom-lg: -40px;
$offset-top-xl: 60px;
$offset-bottom-xl: -60px;
$header-offset-factor-parallax-new: 7;

html {
	overflow: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	transition: opacity 0.5s ease-in;

	&.has-menu-overlay {
		overflow: hidden;
	}
}

body {
	/* overflow-y: scroll !important; */
	overflow: hidden;
	height: 100% !important;
	position: relative;
	-webkit-overflow-scrolling: touch !important;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	&.has-menu-overlay {
		overflow: hidden;

		.content {
			opacity: 0.1;
			pointer-events: none;
		}

		.content,
		footer.footer,
		header.header {
			transform: translateX(- $navbar-offcanvas-width);
		}
	}

	.content {
		margin-top: $header-height-xs;
		transition: all 0.15s ease-in;
	}

	.has-z-index--0 {
		position: relative;
		z-index: 0;
	}

	.has-z-index--1 {
		position: relative;
		z-index: 1;
	}

	.has-z-index--2 {
		position: relative;
		z-index: 2;
	}

	.is-left-aligned {
		transform: translateX(calc(((100vw - 100%) / 2) * -1));
	}

	.is-right-aligned {
		transform: translateX(calc((100vw - 100%) / 2));
	}
}

iframe {
	max-width: 100%;
}
@include media-breakpoint-up(sm) {
	body {
		.content {
			margin-top: calc(#{$header-height-sm} - #{$offset-top-sm});
		}

		&.has-menu-overlay {
			overflow: hidden;

			.content,
			footer.footer,
			header.header {
				transform: translateX(- $navbar-offcanvas-width-sm);
			}
		}
	}
}
@include media-breakpoint-up(md) {
	body {
		.content {
			margin-top: calc(#{$header-height-md} - #{$offset-top-md});
		}

		&.has-menu-overlay {
			overflow: hidden;

			.content {
				opacity: 0.1;
			}

			.content,
			footer.footer,
			header.header {
				transform: translateX(- $navbar-offcanvas-width-md);
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	body {
		.content {
			margin-top: calc(#{$header-height-lg} - #{$offset-top-lg});
		}

		&.has-menu-overlay {
			overflow: hidden;

			.content {
				opacity: 0.1;
			}

			.content,
			footer.footer,
			header.header {
				transform: translateX(- $navbar-offcanvas-width-lg);
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	body {
		.content {
			margin-top: calc(#{$header-height-xl} - #{$offset-top-xl});
		}

		&.has-menu-overlay {
			overflow: hidden;

			.content {
				opacity: 0.1;
			}

			.content,
			footer.footer,
			header.header {
				transform: translateX(- $navbar-offcanvas-width-xl);
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	body {
		&.has-menu-overlay {
			.content,
			footer.footer,
			header.header {
				transform: translateX(- $navbar-offcanvas-width-xxl);
			}
		}
	}
}
