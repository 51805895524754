.grid-teaser {
	position: relative;
	width: 100%;
	height: auto;
	transform: translateY($offset-top);
	z-index: 2;
	@extend .container-fluid;

	.grid-teaser__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.grid-teaser__body {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			padding-left: $container-outer-padding;
			padding-right: $container-outer-padding;
		}
	}
}
@include media-breakpoint-up(sm) {
	.grid-teaser {
		transform: translateY($offset-bottom-sm);

		.grid-teaser__wrapper {

			.grid-teaser__body {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				padding-left: calc((#{$grid-gutter-width} / 2) - (15px));
				padding-right: calc((#{$grid-gutter-width} / 2) - (15px));
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.grid-teaser {
		transform: translateY($offset-bottom-md);

		.grid-teaser__wrapper {
			margin-left: -15px;
			margin-right: -15px;

			.grid-teaser__body {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.grid-teaser {
		ransform: translateY($offset-bottom-lg);
	}
}
@include media-breakpoint-up(xl) {
	.grid-teaser {
		transform: translateY($offset-bottom-xl);
	}
}
