/* Toolkit Styles */

@import "./design/index",
				"./design/global";

@import "./plugins/slick";
/* Atom */

@import "./patterns/atom/logo",
				"./patterns/atom/button",
				"./patterns/atom/anchor",
				"./patterns/atom/section",
				"./patterns/atom/icon",
				"./patterns/atom/headline",
				"./patterns/atom/image",
				"./patterns/atom/blockquote";

/* Molecule */

@import "./patterns/molecule/post",
				"./patterns/molecule/slider",
				"./patterns/molecule/filter",
				"./patterns/molecule/teaser",
				"./patterns/molecule/teaser-related",
				"./patterns/molecule/stage",
				"./patterns/molecule/person",
				"./patterns/molecule/dialog",
				"./patterns/molecule/searchform",
				"./patterns/molecule/widget-pagination",
				"./patterns/molecule/widget-person",
				"./patterns/molecule/navbar",
				"./patterns/molecule/widget-event",
				"./patterns/molecule/modal";

/* Organism */

@import "./patterns/organism/header",
				"./patterns/organism/header-page",
				"./patterns/organism/header-event",
				"./patterns/organism/header-event-detail",
				"./patterns/organism/footer",
				"./patterns/organism/grid-feed",
				"./patterns/organism/grid-teaser",
				"./patterns/organism/section-gallery",
				"./patterns/organism/section-article",
				"./patterns/organism/section-summary",
				"./patterns/organism/section-person",
				"./patterns/organism/section-featured",
				"./patterns/organism/section-event",
				"./patterns/organism/section-related",
				"./patterns/organism/section-search",
				"./patterns/organism/section-text";

/* Bootstrap */
@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
