$color-disabled: $white;
$button-height: 54px;
$button-height-icon: 28px;
$button-height-icon-lg: 32px;
$button-padding: ($spacer * 2.5);
$button-padding-lg: ($spacer * 3);

.katana {
	.btn {
		display: inline-flex;
		user-select: none;
		align-items: center;
		height: $button-height;
		padding: 0 $button-padding;
		border: none;
		cursor: pointer;
		border-radius: 0;
		position: relative;
		box-shadow: $box-shadow--100;
		text-decoration: none;

		span {
			font-family: $font-family-monospace;
			font-size: $font-size--100;
			letter-spacing: 0.25px;
			text-decoration: none;
			line-height: 0;
		}

		svg {
			height: $button-height-icon;
			margin-top: -1px;
		}

		&.disabled,
		&:disabled {
			background: $color-disabled;
			cursor: not-allowed;
			pointer-events: none;
			filter: alpha(opacity=65);
			-webkit-box-shadow: none;
			box-shadow: none;
		}

		&.focus,
		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&:hover {
			span {
				text-decoration: underline;
			}
		}

		&.btn--link {
			background: transparent;
			padding: 0;
			height: auto;

			span {
				color: $secondary;
			}

			svg {
				fill: $primary;
			}
		}

		&.btn--default {
			background: $white;

			span {
				color: $primary;
			}

			svg {
				fill: $primary;
			}

			&.disabled,
			&:disabled {
				background: $color-disabled;
			}
		}

		&.btn--primary {
			background: $primary;
			color: $white;

			span {
				color: $white;
			}

			.button-icon {
				background: $white;
				padding: $spacer;
				width: 54px;
				height: 54px;
				box-shadow: $box-shadow--100;

				svg {
					fill: $primary;
				}
			}

			&.btn--icon-right {
				padding-right: ($spacer * 4);

				.button-icon {
					right: - ($spacer * 2.2);
					top: -($spacer*0.8);
				}
			}

			&.disabled,
			&:disabled {
				background: $color-disabled;
			}
		}

		&.btn--danger {
			background: $danger;
			color: $white;

			&:hover{
				color: $white;
			}
		}

		&.btn--link {
			height: auto;

			span {
				color: $primary;
				text-decoration: underline;
			}

			&.btn--icon-right {
				svg {
					top: inherit;
					right: -$spacer * 4.5;
				}
			}
		}

		&.btn--icon {
			padding: 0;
			align-items: center;
			justify-content: center;

			span {
				display: none;
			}

			svg {
				margin: 0;
			}
		}

		&.btn--icon-right {
			.button-icon {
				position: absolute;
				right: - $button-padding / 2;
				top: calc((#{$button-height} - #{$button-height-icon}) / 2);
				height: auto;
				display: flex;
			}
		}

		&.btn--icon-left {
			.button-icon {
				order: 1;
				position: absolute;
				left: - $button-padding / 2;
				top: calc((#{$button-height} - #{$button-height-icon}) / 2);
				height: auto;
				display: flex;
			}

			.span {
				order: 2;
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.katana {
		.btn {
			padding: 0 $button-padding-lg;

			span {
				font-size: $font-size--175;
			}

			svg {
				height: $button-height-icon-lg;
			}
		}
	}
}
