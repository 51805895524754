$header-event-image-height: 200px;
$header-event-image-height-sm: 260px;
$header-event-image-height-md: 400px;
$header-event-image-height-lg: 480px;
$header-event-image-height-xl: 640px;
$header-event-image-height-xxl: 720px;

.header-event-detail {
	margin-top: $header-height-xs;
	width: 100%;
	height: auto;
	@extend .container-fluid;

	.header-event-detail__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.header-event-detail__container {
			flex: 0 0 100%;
			max-width: 100%;

			.header-event-detail__container-item {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				z-index: 2;
				flex: 0 0 100%;
				max-width: 100%;
				padding-left: $container-outer-padding;
				padding-right: $container-outer-padding;

				.header-event-detail__header {
					padding: $article-bottom-margin 0;

					.btn {
						margin-bottom: $spacer;
						&.btn--danger {
							svg.icon {
								transition-property: transform;
								transition-duration: 0.8s;
								animation-name: bounce;
								animation-duration: 0.8s;
								animation-iteration-count: infinite;
								animation-timing-function: ease-in-out;
							}
							@keyframes bounce {
								0%,
								100%,
								20%,
								60% {
									-webkit-transform: translateX(0);
									transform: translateX(0);
								}

								40% {
									-webkit-transform: translateX(-5px);
									transform: translateX(-5px);
								}

								80% {
									-webkit-transform: translateX(-2px);
									transform: translateX(-2px);
								}
							}
						}
					}

					.header-event-detail__title {
						margin: 0;
					}

					.header-event-detail__time {
						margin-top: $spacer;
						font-weight: bold;
						font-size: 16px;
						display: block;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.header-event-detail {
		margin-top: $header-height-sm;

		.header-event-detail__wrapper {
			.header-event-detail__container {
				.header-event-detail__container-item {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding: 0 ($grid-gutter-width / 2);

					.header-event-detail__header {
						padding: $article-bottom-margin-sm 0;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.header-event-detail {
		margin-top: $header-height-md;

		.header-event-detail__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.header-event-detail__container {
				.header-event-detail__container-item {
					flex: 0 0 calc((83.33333 - #{$grid-gutter-width}));
					max-width: calc((83.33333 - #{$grid-gutter-width}));
					padding: 0 ($grid-gutter-width / 2);

					.header-event-detail__header {
						padding: $article-bottom-margin-md 0;

						.header-event-detail__time {
							font-size: 20px;
							margin-top: ($spacer * 1.5);
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.header-event-detail {
		margin-top: $header-height-lg;

		.header-event-detail__wrapper {
			.header-event-detail__container {
				.header-event-detail__container-item {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
					margin-left: 16.66667%;

					.header-event-detail__header {
						padding: $article-bottom-margin-lg 0;

						.btn {
							margin-bottom: ($spacer * 1.5);
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.header-event-detail {
		margin-top: $header-height-xl;
	}
}
