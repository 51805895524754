// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 10px;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 2),
  3: ($spacer * 3),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8)
), $spacers);
