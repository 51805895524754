$blockquote-size: 60px;
$blockquote-size-lg: 70px;

blockquote {
	font-size: $font-post-base-xs;
	position: relative;
	border-top: 2px solid $primary;
	border-bottom: 2px solid $primary;
	margin-bottom: 0;
	padding: ($spacer * 1.5) 0;
}
@include media-breakpoint-up(sm) {
	blockquote {
		font-size: $font-post-base-sm;
	}
}
@include media-breakpoint-up(md) {
	blockquote {
		font-size: $font-post-base-md;
		padding: ($spacer * 2) 0;
	}
}
@include media-breakpoint-up(lg) {
	blockquote {
		font-size: $font-post-base-lg;
	}
}
@include media-breakpoint-up(xl) {
	blockquote {
		font-size: 30px;
	}
}

@include media-breakpoint-up(xxl) {
	blockquote {
		padding: ($spacer * 3) 0;
	}
}
