$slider-arrow-height: 40px;
$slider-arrow-widht: 80px;

.slick-slider {
	z-index: 2;

	.slick-list {
		padding: 0;
		overflow: hidden;

		.slider__slide{
			&:focus {
				outline: none;
			}
		}
	}

	.slick-next,
	.slick-prev {
		position: absolute;
		z-index: 2;
		height: $slider-arrow-height;
		width: $slider-arrow-widht;
		border: none;
		font-size: 0;
		line-height: 0;
		cursor: pointer;
		padding: 10px;
		background: transparent;
		top: calc(50% - (#{$slider-arrow-height} / 2));

		&.slick-disabled{
			display: none!important;
		}

		&:focus {
			outline: none;
		}

		&.slick-disabled{
			display: none;
		}
	}

	.slick-prev {
		left: calc(#{$slider-arrow-widht} / 2 * -1);

		&:before {
			content: url("/wp-content/themes/companions/assets/images/ic_arrow_left.svg");
		}
	}

	.slick-next {
		right: calc(((16.66667% + (#{$grid-gutter-width} / 2)) + #{$container-outer-padding-lg}) + 16.6666666667px + (#{$slider-arrow-widht} / 2 * -1));

		&:before {
			content: url("/wp-content/themes/companions/assets/images/ic_arrow_right.svg");
		}
	}
}

@include media-breakpoint-up(md) {
	.slick-slider {

		// .slick-next,
		// .slick-prev {
		// 	height: 40px;
		// 	width: 40px;
		// }
		//
		// .slick-prev {
		// 	left: 0px;
		// 	transform: none;
		// }
		//
		// .slick-next {
		// 	right: 0px;
		// 	transform: none;
		// }
	}
}

@include media-breakpoint-up(lg) {
	.slick-slider {

		// .slick-prev {
		// 	left: 0;
		// }
		//
		// .slick-next {
		// 	right: 20px;
		// }
	}
}
