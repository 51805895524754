$section-related-offset: $grid-gutter-width;
$section-related-offset-sm: $grid-gutter-width;
$section-related-offset-md: $grid-gutter-width;
$section-related-offset-lg: $grid-gutter-width;
$section-related-offset-xl: $grid-gutter-width;
$section-related-offset-xxl: $grid-gutter-width;

.section-related {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-related__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-related__container {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.section-related__container-item {
				z-index: 2;
				width: 100%;

				.section-related__header {
					width: 100%;
					flex-shrink: 0;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;
					margin-bottom: $article-bottom-margin;

					.section-related__title{
						margin-bottom: 0;
					}
				}

				.section-related__body {
					padding: 0 $container-outer-padding;

					.teaser-related {
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-related {
		margin-bottom: $article-bottom-margin-sm;

		.section-related__wrapper {
			.section-related__container {
				.section-related__container-item {
					.section-related__header {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						margin-left: 8.33333%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
						margin-bottom: $article-bottom-margin-sm;
					}

					.section-related__body {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						margin-left: 8.33333%;
						padding: 0 ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-related {
		margin-bottom: $article-bottom-margin-md;

		.section-related__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-related__container {
				.section-related__container-item {
					.section-related__header {
						flex: 0 0 75%;
						max-width: 75%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 8.33333%;
						margin-bottom: $article-bottom-margin-md;
					}

					.section-related__body {
						flex: 0 0 83.33333%;
						max-width: 83.33333%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 8.33333%;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-related {
		margin-bottom: $article-bottom-margin-lg;

		.section-related__wrapper {
			.section-related__container {
				.section-related__container-item {
					.section-related__header {
						flex: 0 0 58.33333%;
						max-width: 58.33333%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
						margin-bottom: $article-bottom-margin-lg;
					}

					.section-related__body {
						flex: 0 0 66.66667%;
						max-width: 66.66667%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-related {
		.section-related__wrapper {
			.section-related__container {
				.section-related__container-item {
					.section-related__header {
						flex: 0 0 58.33333%;
						max-width: 58.33333%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;
					}

					.section-related__body {
						flex: 0 0 66.66667%;
						max-width: 66.66667%;
						padding: 0 ($grid-gutter-width / 2);
						margin-left: 16.66667%;

					}
				}
			}
		}
	}
}
