$section-icon-size: 26px;
$section-icon-size-md: 32px;

.section-summary {
	width: 100%;
	height: auto;
	margin-bottom: $article-bottom-margin;
	@extend .container-fluid;

	.section-summary__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.section-summary__body {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.section-summary__body-item {
				padding-left: $container-outer-padding;
				padding-right: $container-outer-padding;

				.section-summary__sum {
					border-top: 2px solid $primary;
					border-bottom: 2px solid $primary;
					margin-bottom: 0;
					padding: ($spacer * 2) 0;

					.section-summary__icon {
						position: absolute;
						top: calc((#{$section-icon-size} / 2) * -1);
						left: calc(50% - (#{$section-icon-size} / 2));
						background: $white;

						svg {
							width: $section-icon-size;
							height: $section-icon-size;
						}
					}

					p {
						margin-bottom: 0;
					}

					.section-summary__title {
						margin-bottom: ($spacer * 1);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.section-summary {
		margin-bottom: $article-bottom-margin-sm;

		.section-summary__wrapper {
			.section-summary__body {
				.section-summary__body-item {
					flex: 0 0 83.33333%;
					max-width: 83.33333%;
					margin-left: 8.33333%;
					padding-left: ($grid-gutter-width / 2);
					padding-right: ($grid-gutter-width / 2);
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.section-summary {
		margin-bottom: $article-bottom-margin-md;

		.section-summary__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.section-summary__body {
				.section-summary__body-item {
					.section-summary__sum {
						padding: ($spacer * 3) 0;

						.section-summary__icon {
							top: calc((#{$section-icon-size-md} / 2) * -1);
							left: calc(50% - (#{$section-icon-size-md} / 2));

							svg {
								width: $section-icon-size-md;
								height: $section-icon-size-md;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.section-summary {
		margin-bottom: $article-bottom-margin-lg;

		.section-summary__wrapper {
			.section-summary__body {
				.section-summary__body-item {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
					margin-left: 16.66667%;
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.section-summary {

		.section-summary__wrapper {
			.section-summary__body {
				.section-summary__body-item {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
					margin-left: 16.66667%;
				}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.section-summary {
		.section-summary__wrapper {
			.section-summary__body {
				.section-summary__body-item {
					.section-summary__sum {
						padding: ($spacer * 4) 0;
					}
				}
			}
		}
	}
}
