.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  transition: all 0.15s ease-in;
  height: $header-height-xs;

  &.shrinked {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.04);
  }
}

@include media-breakpoint-up(sm) {
  .header {
    height: $header-height-sm;
    &.shrinked {
      height: $header-height-shrinked-sm;
    }
  }
}

@include media-breakpoint-up(md) {
  .header {
    height: $header-height-md;
    &.shrinked {
      height: $header-height-shrinked-md;
    }
  }
}

@include media-breakpoint-up(lg) {
  .header {
    height: $header-height-lg;
    &.shrinked {
      height: $header-height-shrinked-lg;
    }
  }
}

@include media-breakpoint-up(xl) {
  .header {
    height: $header-height-xl;
    &.shrinked {
      height: $header-height-shrinked-xl;
    }
  }
}
