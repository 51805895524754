.widget-pagination {
	width: 100%;
	height: auto;
	z-index: 0;
	position: relative;
	transform: translateY($offset-top);
	@extend .container-fluid;

	.widget-pagination__wrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;

		.widget-pagination__container {
			width: 100%;

			.widget-pagination__container-item {
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				z-index: 2;
				flex-wrap: wrap;
				padding-top: ($spacer * 5);
				padding-bottom: ($spacer * 5);

				.widget-pagination__header {
					display: flex;
					flex: 0 0 100%;
					max-width: 100%;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;
					margin-bottom: ($spacer * 2);
					justify-content: center;

					.widget-pagination__title {
						margin-bottom: 0;
						line-height: normal;
						font-size: $font-size--100;
						color: $primary;
						font-family: $font-family-monospace;
						letter-spacing: 0.25px;
					}
				}

				.widget-pagination__body {
					display: flex;
					flex: 0 0 100%;
					max-width: 100%;
					position: relative;
					justify-content: center;
					padding-left: $container-outer-padding;
					padding-right: $container-outer-padding;

					.btn {
						&.btn--primary {
							&:hover {
								.button-icon {
									svg.icon {
										transition-property: transform;
										transition-duration: 1s;
										animation-name: rotate;
										animation-duration: 2.5s;
										animation-iteration-count: infinite;
										animation-timing-function: linear;
									}
									@keyframes rotate {
										from {
											transform: rotate(0deg);
										}

										to {
											transform: rotate(360deg);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(sm) {
	.widget-pagination {
		transform: translateY($offset-top-sm);

		.widget-pagination__wrapper {
			.widget-pagination__container {
				.widget-pagination__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);

					.widget-pagination__header {
						flex: 0 0 100%;
						max-width: 100%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
					}

					.widget-pagination__body {
						flex: 0 0 100%;
						max-width: 100%;
						padding-left: ($grid-gutter-width / 2);
						padding-right: ($grid-gutter-width / 2);
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	.widget-pagination {
		transform: translateY($offset-top-md);

		.widget-pagination__wrapper {
			margin-left: -25px;
			margin-right: -25px;

			.widget-pagination__container {
				.widget-pagination__container-item {
					padding-top: ($spacer * 6);
					padding-bottom: ($spacer * 6);
				}
			}
		}
	}
}
@include media-breakpoint-up(lg) {
	.widget-pagination {
		transform: translateY($offset-top-lg);

		.widget-pagination__wrapper {
			.widget-pagination__container {
				flex: 0 0 100%;
				max-width: 100%;

				.widget-pagination__container-item {
					width: 100%;
					padding: ($spacer * 10) 0;
					display: flex;
					align-items: flex-start;

					.widget-pagination__header {
						.widget-pagination__title {
							font-size: $font-size--175;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.widget-pagination {
		transform: translateY($offset-top-xl);

		.widget-pagination__wrapper {
			.widget-pagination__container {
				.widget-pagination__container-item {}
			}
		}
	}
}
@include media-breakpoint-up(xxl) {
	.widget-pagination {
		transform: translateY($offset-top-xl);

		.widget-pagination__wrapper {
			.widget-pagination__container {
				.widget-pagination__container-item {
					.widget-pagination__header {}
				}
			}
		}
	}
}
