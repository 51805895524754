.anchor {
	padding-top: ($header-height-shrinked-xs + 20px);
	margin-top: calc((#{$header-height-shrinked-xs} + 20px) * -1);
}

.anchor-scroll {
	.button-icon{
		-webkit-animation: rotating 5s ease-in-out infinite;
		-moz-animation: rotating 5s ease-in-out infinite;
		-ms-animation: rotating 5s ease-in-out infinite;
		-o-animation: rotating 5s ease-in-out infinite;
		animation: rotating 5s ease-in-out infinite;
	}

	@keyframes rotating {
	  0% {
	    -ms-transform: rotate(0deg);
	    -moz-transform: rotate(0deg);
	    -webkit-transform: rotate(0deg);
	    -o-transform: rotate(0deg);
	    transform: rotate(0deg);
	  }
	  5% {
	    -ms-transform: rotate(180deg);
	    -moz-transform: rotate(1800deg);
	    -webkit-transform: rotate(180deg);
	    -o-transform: rotate(180deg);
	    transform: rotate(180deg);
	  }

		100%{
			-ms-transform: rotate(180deg);
	    -moz-transform: rotate(1800deg);
	    -webkit-transform: rotate(180deg);
	    -o-transform: rotate(180deg);
	    transform: rotate(180deg);
		}
	}
}

@include media-breakpoint-up(sm) {
	.anchor {
		padding-top: ($header-height-shrinked-sm + 20px);
		margin-top: calc((#{$header-height-shrinked-sm} + 20px) * -1);
	}
}
@include media-breakpoint-up(md) {
	.anchor {
		padding-top: ($header-height-shrinked-md + 20px);
		margin-top: calc((#{$header-height-shrinked-sm} + 20px) * -1);
	}

	.anchor-scroll {

	}
}
@include media-breakpoint-up(lg) {
	.anchor {
		padding-top: ($header-height-shrinked-lg + 20px);
		margin-top: calc((#{$header-height-shrinked-lg} + 20px) * -1);
	}
}
@include media-breakpoint-up(xl) {
	.anchor {
		padding-top: ($header-height-shrinked-xl + 20px);
		margin-top: calc((#{$header-height-shrinked-xl} + 20px) * -1);
	}
}
